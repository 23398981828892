import React, {useState, useEffect, useRef} from "react";
import ReactDOM from "react-dom";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { Link } from "react-router-dom";

import Chart from 'react-apexcharts'
import Util from "../helpers/util";

export const OwnedPeakCard = props => {
    const { peak, onClick, showToast } = props;

    return (
        <div className="card rounded-3 text-black"
             onClick={() => onClick(peak)}>
            <div className="card-body">
                <img className="mtn-img" src={peak.img_url}/>
                <div className="mtn-stats">
                    <label className="peak-name">{peak.name}</label>
                    <p className="captured-on">
                        Created on:&nbsp;&nbsp;
                        {Util.friendlyDate(peak.created_on)}
                    </p>
                    <div className="scans">
                        <label>{peak.scans}</label>
                        {peak.scans != peak.recent_scans &&
                            <label className="new-scans">+{peak.recent_scans}</label>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};
