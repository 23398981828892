import React, {useEffect, useRef, useState} from 'react';
import ReactDOM from 'react-dom';
import {Modal, Button, FormText, FormGroup, FormLabel, FormControl} from 'react-bootstrap';
import {Link, useNavigate} from "react-router-dom";

import {ModalBase} from "../modals/modal_base";
import { WorldMap } from "../components/world_map";
import {createHeatmap, createMarkers} from "../helpers/map";
import QuadRect from "../helpers/quad_rect";
import { useStore } from '../store';
import Util from '../helpers/util';

import ProfileImg from '../assets/images/logo_icon.svg'
import { LogEntryCard } from "../cards/log_entry_card";
import {CellSignal} from "../components/cell_signal";
import Close from "../assets/images/close.svg";
import {PeakOwnerCard} from "../cards/peak_owner_card";

export const PeakModal = (props) => {
    const {peak, land, peak_owner, land_owner, log_entries, captured, cell_signal, showToast, onClose} = props

    const [state, setState] = useState({
        show_desc: false
    })
    const { show_desc } = state

    useEffect(() => {
    }, [])

    const navigate = useRef(useNavigate())

    const handleToggleDesc = e => {
        setState(prev => ({...prev,
            show_desc: !show_desc,
        }))
    }

    const scan_txt = ('scans' in peak && peak.scans != 0)? peak.scans: 'None'
    const log_txt = (log_entries.length != 0)? log_entries.length: 'None'
    const img_gray = { filter: 'grayscale(100%)' }

    const desc_style = { color: 'var(--action-color)' }
    if ( show_desc ) {
        desc_style.transform = 'rotate(90deg)'
    }

    return (
        <>
            <div className="heading">
                {onClose != undefined &&
                    <img src={Close}
                         className="close-btn"
                         onClick={() => onClose(false)}/>
                }
                <div className="flex-column">
                    <h2 className="peak-name">
                        {peak.name}
                    </h2>
                    <p className="owner-name">
                        <span className="label">Captured: </span>
                        {captured != null? Util.friendlyDate(captured.created_on): "Never"}
                    </p>
                    {peak.msg != null && peak.msg.length > 0 && <>
                        <p className="owner-name clickable" onClick={handleToggleDesc}>
                            <span className="label">
                                Description
                            </span>
                            <span style={desc_style} className="label transition"> > </span>
                        </p>
                        <p style={{ display: (show_desc)? 'block': 'none'}} className="owner-name mt-10 clickable" onClick={handleToggleDesc}>
                            {peak.msg}
                        </p>
                    </>
                    }
                </div>
                {false &&
                    <img className="photo" src={(peak_owner != null? peak_owner.profile_url: null) || ProfileImg}/>
                }
            </div>
            <img className="slider"
                 src={peak.img_url || ProfileImg}
                 style={(captured == null)? img_gray: {}}
            />
            <div className="stats stats--cards">
                <div>
                    <h4>Elevation</h4>
                    <p>{Util.numberWithCommas(peak.elv * 3.28084)} ft</p>
                </div>
                <CellSignal cell_signal={cell_signal} />
            </div>
            <div className="stats stats--cards">
                <div>
                    <h4>Logs</h4>
                    <p>{log_txt}</p>
                </div>
                <div>
                    <h4>Scans</h4>
                    <p>{scan_txt}</p>
                </div>
            </div>
            {peak_owner != null && <>
                <div className="card-col mb-20">
                    <p className="owner-name">
                        <span className="label">Owner</span>
                    </p>
                    <PeakOwnerCard
                        owner={peak_owner}
                        peak={peak}
                    />
                </div>
            </>
            }

            <div className="card-col">
                {log_entries.length > 0 &&
                    <p className="owner-name">
                        <span className="label">Peak Logs</span>
                    </p>
                }
                {Object.entries(log_entries).map(([idx, log_entry]) =>
                    <LogEntryCard key={`card_key_${idx}`}
                                    log_entry={log_entry}
                    />
                )}
            </div>
        </>
    );
}
