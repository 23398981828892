import React, {useEffect, useRef, useState} from 'react';
import ReactDOM from 'react-dom';
import { Modal, Button, FormText, FormGroup, FormLabel, FormControl } from 'react-bootstrap';
import { Link } from "react-router-dom";
import Util from '../helpers/util';
import * as H3 from 'h3-js'

import { useStore } from '../store';
import { MAP_API_KEY } from "../settings";
import {
    createH3Projection,
    createH3Land,
    createHeatmap,
    createMarkers,
    createPolygons,
    createTerrian,
    getMapProjection,
    removeLayer,
    removeH3Land, drawH3Cells, createPeakCircles
} from "../helpers/map";

import mapboxgl from 'mapbox-gl/dist/mapbox-gl-unminified.js';
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';
import 'mapbox-gl/dist/mapbox-gl.css';
import QuadRect from "../helpers/quad_rect";

import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import * as Geo from "../helpers/geo";
import { PeakType } from "../helpers/consts";

mapboxgl.workerClass = MapboxWorker;
mapboxgl.accessToken = MAP_API_KEY


export const WorldMap = React.forwardRef((props, map_ref) => {
    let {height, origin, query_elv, trigger_location, onPlaces, onKnobs, onLocation, onMarker, onLand, onMove, showToast} = props

    const safe_origin = origin || { lat: 43.7, lng: -113.3, zoom: 4.5, elv: 0 }
    const [gps, setGps] = useState( safe_origin )

    const map_status = useRef( {
        loc: { lat: 47.7, lng: -116.8, zoom: 9 },
        move_reload1: false,
        move_reload2: false,
    })
    const map_data = useRef({
        markers: {},
        peaks: [],
        my_peaks: {},
        knobs: [],
        my_knobs: {},
        my_favorites: {},
        lands: [],
        poly_bounds: null,
        poly_res: 0,
        cell_drawn: { lat: 0, lng: 0 },
    })

    const map_local = useRef(null)
    const map_container = useRef(null)
    const map = (map_ref)? map_ref: map_local

    if ( onPlaces == undefined || onPlaces == null ) {
        onPlaces = () => {}
    }
    if ( onKnobs == undefined || onKnobs == null ) {
        onKnobs = () => {}
    }
    if ( onLand == undefined || onLand == null ) {
        onLand = () => {}
    }
    if ( onLocation == undefined || onLocation == null ) {
        onLocation = () => {}
    }
    if ( onMarker == undefined || onMarker == null ) {
        onMarker = () => {}
    }
    if ( onMove == undefined || onMove == null ) {
        onMove = () => {}
    }

    useEffect(() => {
        //initialize the map
        map.current = new mapboxgl.Map({
            container: map_container.current,
            //style: 'mapbox://styles/mapbox/streets-v11',
            style: 'mapbox://styles/mapbox/outdoors-v11',
            center: [gps.lng, gps.lat],
            zoom: gps.zoom,
            //pitch: 76, bearing: 150,
            projection: 'globe',
        });

        //Setup the address search
        const geocoder = new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            mapboxgl: mapboxgl,
            marker: false,
        })
        map.current.addControl(geocoder, 'top-right');

        //Setup the fullscreen control
        //map.current.addControl(new mapboxgl.FullscreenControl(), 'top-right')

        //Setup the scale controls -- Measuring stick
        const scale = new mapboxgl.ScaleControl({
            unit: 'imperial'
        });
        map.current.addControl(scale, 'bottom-left')

        //Setup
        const nav = new mapboxgl.NavigationControl({
            showZoom: false,
            showCompass: true,
            visualizePitch: true,
        });
        map.current.addControl(nav, 'bottom-right');

        //Create the geo location object
        const geolocate = new mapboxgl.GeolocateControl({
            positionOptions: {
                enableHighAccuracy: true
            },
            trackUserLocation: true,
            showUserHeading: true
        })
        map.current.addControl( geolocate, 'top-right' )

        geolocate.on('geolocate', data => {
            //console.log(data)
            let gps =  {
                lat: data.coords.latitude,
                lng: data.coords.longitude,
                spd: data.coords.speed,
                elv: data.coords.altitude,
            }

            //Setup elevation
            if ( gps.elv == null ) {
                gps.elv = (query_elv)? map.current.queryTerrainElevation(gps, {exaggerated: false}) : 0
            }

            onLocation( gps )
        })

        //On move callback
        map.current.on('move', () => {
            //Set that we want to reload
            map_status.current.move_reload1 = map_status.current.move_reload2 = true
            map_status.current.loc = {
                lat: map.current.getCenter().lat,
                lng: map.current.getCenter().lng,
                zoom: map.current.getZoom(),
            }

            //update the GPS location
            setGps(prev => ({ ...prev,
                ...map_status.current.loc
            }))

            onMove()
        });

        map.current.on('click', function(e) {
            //Make sure we hit a feature
            const features = map.current.queryRenderedFeatures(e.point, { layers: ['knobs-layer', 'knobs_mine-layer'] })
            if ( features.length > 0 ) {
                const [lng, lat] = features[0].geometry.coordinates
                handleKnobClick( lat, lng )
                return
            }

            const loc = e.lngLat
            const { lat, lng } = loc
            const zoom = map.current.getZoom()

            const cell = H3.latLngToCell( lat, lng, 9 )
            const elv = map.current.queryTerrainElevation( loc, { exaggerated: false })

            console.log( (cell in map_data.current.lands)? "This is owned": "Free to buy")

            /*
            //If this cell is owned by pin, do nothing with "land"
            if ( cell in map_data.current.lands ) {
                return
            }
             */

            //If we are zoomed out, clicking will center and move in
            if ( zoom < 12.5 ) {
                map.current.flyTo({ center: [lng, lat], zoom: Math.min( 12.4, zoom + 1 ) })
            }
            //Show!
            else if ( !(cell in map_data.current.lands) && zoom >= 12.5 ) {
                onLand({lat, lng, cell, elv})
            }
        });

        map.current.on('load', () => {
            map.current.setFog({ 'star-intensity': 1 });

            createTerrian( map.current )

            loadPeaks()
            loadKnobs()

            //Trigger right now?
            if ( trigger_location ) {
                geolocate.trigger()
            }
        })

        //Configure the loader to handle movement
        const interval = setInterval( updateView, 50);
        return () => clearInterval(interval);
    }, [])

    const loadPeaks = () => {
        //Get a list of all members for this tenant descending order.
        Util.fetch_js( '/api/peak/list/', {peak_type: PeakType.TAG},
            (js) => {
                const lands = {}
                js.lands.forEach( x => { lands[x.h3_cell] = x })

                map_data.current.peaks = js.peaks
                map_data.current.my_peaks = js.my_peaks
                map_data.current.my_favorites = js.my_favorites
                map_data.current.lands = lands

                const peaks_f = js.peaks.filter( x => !(x.uid in js.my_peaks) )
                const my_peaks_f = js.peaks.filter( x => x.uid in js.my_peaks )

                //Setup the heat map of all the data
                createHeatmap( map.current, 'peaks', peaks_f, 'purple' )
                createHeatmap( map.current, 'peaks_mine', my_peaks_f, 'green' )

                onPlaces( js.peaks )
            })
    }

    const loadKnobs = () => {
        //Get a list of all members for this tenant descending order.
        Util.fetch_js( '/api/peak/list_points/', {peak_type: PeakType.KNOB},//, min_elv: 3048},
            (js) => {
                const knobs = []
                for ( let i = 0; i < js.knob_count; i++ ) {
                    knobs.push({
                        //uid: js.uids[i],
                        //name: js.names[i],
                        lat: js.lats[i],
                        lng: js.lngs[i],
                        //elv: js.elvs[i],
                    })
                }

                map_data.current.knobs = knobs
                map_data.current.my_knobs = js.my_knobs

                const knobs_f = knobs.filter( (x, idx) => !(idx in js.my_knobs) )
                const my_knobs_f = knobs.filter( (x, idx) => idx in js.my_knobs )

                //Draw the peaks
                createPeakCircles( map.current, 'knobs', knobs_f, 'purple' )
                createPeakCircles( map.current, 'knobs_mine', my_knobs_f, 'green' )

                //Just for my knobs
                createHeatmap( map.current, 'knobs_mine', my_knobs_f, 'green' )

                onKnobs( js.knobs )
            })
    }

    const handleKnobClick = (lat, lng) => {
        // Do something with the properties (e.g., display in a popup)
        Util.fetch_js( '/api/peak/by_lat_lng/', { lat, lng },
            js => {
                onMarker(js.peak)
            },
            console.log )
    }

    const updateView = () => {
        //All of this ensure we don't double up on requests
        if (map.current == null || !map_status.current.move_reload2) {
            return
        }
        if (map_status.current.move_reload1) {
            map_status.current.move_reload1 = false
            return
        }
        map_status.current.move_reload2 = false

        const { markers, peaks, my_peaks, my_favorites, lands, poly_res, poly_bounds } = map_data.current
        const zoom = map.current.getZoom()
        console.log(zoom)

        //createPolygons( map.current, 'h3', [{lat: 47, lng: -116}], 10000)

        //Anothing else to do?
        if ( zoom < 8.75 ) {
            // Clear out any markers we currently have
            Object.values(markers).forEach(x => {
                //x.marker.removeListener()
                x.marker.remove()
            })
            map_data.current.markers = {}
            map_data.current.poly_bounds = null
            map_data.current.poly_res = 0

            //Remove any layers
            removeH3Land( map.current, 'h3_land' )

            //Reset the projection
            onPlaces( [] )
            return
        }

        createH3Land( map.current, 'h3_land', Object.keys(lands), 8 )

        //Calcualte the resolution of h3
        if ( zoom > 12.5 ) {
            const d = Geo.distV( map_data.current.cell_drawn, map_status.current.loc )
            console.log( d )
            if ( d >= 3000 ) {
                map_data.current.cell_drawn = map_status.current.loc
                Util.fetch_js('/api/cell_coverage/list/', {...map_status.current.loc, dist: 8500 },
                    js => {
                        const cells = js.cell_coverages.map( x => x.h3_cell )
                        drawH3Cells( map.current, 'cell_coverage', cells  )
                    },
                    showToast )
            }

            /*
            const cur_res = (zoom < 14)? 8: 9

            //Check the projection and update
            const poly_proj = getMapProjection( map.current )
            if ( poly_bounds != null ) {
                poly_proj.forEach(x => {
                    if ( !poly_bounds.contains( x[0], x[1] ) ) {
                        map_data.current.poly_bounds = null
                    }
                })
            }

            //Redraw?
            console.log( `${cur_res} != ${poly_res} || ${map_data.current.poly_bounds == null}` )
            if ( cur_res != poly_res || map_data.current.poly_bounds == null ) {
                createH3Projection( map.current, 'h3', poly_proj, cur_res )
                createH3Land( map.current, 'h3_land', Object.keys(lands) )
            }

            map_data.current.poly_res = cur_res

            //Store a new bounds?
            if ( map_data.current.poly_bounds == null ) {
                const qr = new QuadRect()
                poly_proj.forEach(x => {
                    qr.add( x[0], x[1], 0.1 )
                })
                map_data.current.poly_bounds = qr
            }
             */
        }
        //Clear out the H3
        else {
            map_data.current.cell_drawn = { lat: 0, lng: 0}
            removeH3Land( map.current, 'cell_coverage' )
        }

        //Clear out the H3
        if ( zoom <= 11.5 ) {
            map_data.current.poly_bounds = null
            map_data.current.poly_res = 0
            removeH3Land( map.current, 'h3_land' )
        }

        //Add my markers
        map_data.current.markers = createMarkers(
            map.current, peaks, my_peaks, my_favorites, markers,
            (m) => {
                console.log("Marker clicked")
                onMarker( m.peak )
            })

        //Update my state
        const elv = map.current.queryTerrainElevation( map_status.current.loc, { exaggerated: false })
        setGps(prev => ({...prev,
            elv: Math.floor( elv ),
        }))

        onPlaces( Object.values(markers).map( x => x.peak ) )
    }

    /*
            <pre className='ui-coordinates'>
                Latitude {gps.lat.toFixed(4)}<br/>
                Longitude {gps.lng.toFixed(4)}<br/>
                {(gps.zoom >= 9)? `Elevation ${gps.elv} m`: ''}
            </pre>
     */

    return (
        <div ref={map_container}
             className="map-container container"
             style={{ height: `${height}px`, flexGrow: 1 }} />
    );
})
