import React, {useEffect, useRef, useState} from 'react';
import ReactDOM from 'react-dom';
import {Modal, Button, FormText, FormGroup, FormLabel, FormControl} from 'react-bootstrap';
import {Link, useNavigate} from "react-router-dom";
import Util from "../helpers/util";

import WifiSignal0 from '../assets/images/wifi_signal_0.svg'
import WifiSignal1 from '../assets/images/wifi_signal_1.svg'
import WifiSignal2 from '../assets/images/wifi_signal_2.svg'
import WifiSignal3 from '../assets/images/wifi_signal_3.svg'


export const CellSignal = (props) => {
    const {cell_signal} = props
    console.log( cell_signal )

    const signals = [
        WifiSignal0,
        WifiSignal1,
        WifiSignal2,
        WifiSignal3,
    ]

    return (
        <div>
            <h4>Coverage</h4>
            {(cell_signal == null || cell_signal < 0 || cell_signal > signals.length) &&
                <img src={WifiSignal0} style={{ width: '28px'}} />
            }
            {cell_signal >= 0 && cell_signal < signals.length &&
                <img src={signals[cell_signal]} style={{ width: '28px'}} />
            }
        </div>
    );
}
