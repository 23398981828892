import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Modal, Button, FormText, FormGroup, FormLabel, FormControl } from 'react-bootstrap';
import {Link, useParams, useNavigate} from "react-router-dom";

import Util from '../helpers/util';
import { useStore } from '../store';

import {WorldMap} from "../components/world_map";
import {Faqs} from "../components/faqs";

import ProfileImg from "../assets/images/logo_icon.svg"
import { LogEntryCard } from "../cards/log_entry_card";
import {ModalBase} from "../modals/modal_base";
import {Login} from "../modals/login";
import {SignupModal} from "../modals/signup_modal";
import {ClaimModal} from "../modals/claim_modal";
import { BannerWidget } from "../components/banner_widget";
import { FeaturesWidget } from "../components/features_widget";
import { HowItWorks } from "../components/how_it_works";
import { StatsWidget } from "../components/stats_widget";
import {DiscordWidget} from "../components/discord_widget";
import {WhatIsThisWidget} from "../components/what_is_this_widget";

export const QrPlace = (props) => {
    const { chain_key_uid, peak, zone, is_logged_in, scan_count, totals, showToast } = props

    const {usr_info} = useStore(x => x)

    const [tab, setTab] = useState( 1 )
    const [ state, setState ] = useState({
        gps: null,
        log_entries: props.log_entries,
        show_map: false,
        show_signin: false,
        show_login: false,
        show_claim: false,
    })
    const { gps, log_entries, show_map, show_login, show_signin, show_claim } = state

    const navigate = useRef(useNavigate())

    const [scrollPosition, setScrollPosition] = useState(0);
    const handleScroll = () => {
        setScrollPosition( window.scrollY )
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        if ( log_entries == null && ('uid' in usr_info) ) {
            //Get some details about the peak
            Util.fetch_js('/api/peak/detail/', { peak_uid: peak.uid },
                js => {
                    setState( prev => ({...prev,
                        log_entries: js.log_entries
                    }))
                },
                showToast )

            //Return the event scroller to remove
            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        }
    }, []);

    /*
    useEffect(() => {
        handleHash( windows.location.pathname )
        return history.listen(handleHash)
    }, [history])

    const handleHash = (location) => {
        const hash = location.hash
        if (hash && document.getElementById(hash.substr(1))) {
            // Check if there is a hash and if an element with that id exists
            document.getElementById(hash.substr(1)).scrollIntoView({behavior: "smooth"})
        }
    }
     */

    const handleLocation = (gps) => {
        setState(prev => ({...prev,
            gps,
        }))
    }

    const handleClaim = () => {
        setState(prev => ({...prev,
            show_claim: true,
        }))
    }

    const handleClaimed = (succ) => {
        /*
        if ( succ ) {
            navigate.current('/dashboard')
        }
         */

        setState(prev => ({...prev,
            show_claim: false,
        }))
    }

    const handleLogin = (show_login) => {
        setState(prev => ({...prev,
            show_signin: false,
            show_login,
        }))
    }

    const handleLoggedIn = () => {
        setState(prev => ({...prev,
            show_signin: false,
            show_login: false,
        }))
    }

    const handleSignup = () => {
        setState(prev => ({...prev,
            show_signin: true,
        }))

        /*
        if ( usr_info != null && 'uid' in usr_info ) {
        }

        //Not logged in, going to signin
        else {
            setState(prev => ({...prev,
                show_signin: true,
            }))
        }
         */
    }

    const klass = ( kls, cur_tab ) => {
        return `${kls} ${(cur_tab == tab)? 'active': ''}`
    }

    const logs = log_entries.filter(x => x.msg != "")

    return (
        <>
            <BannerWidget
                title={peak.name}
                subtitle="You found"
                button="Claim your Peak"
                banner={peak.img_url}
                onClick={handleClaim}
                />

            <WhatIsThisWidget
                onClick={handleClaim}
            />

            <HowItWorks
                onClick={handleClaim}
            />

            <DiscordWidget />

            <FeaturesWidget
                desc={`You found a BeenThere QR code on ${peak.name}.`}
                onClick={handleClaim}
                />

            <section className="work-part pt-50" id="how_it_works">
                <div className="container">
                    <div className="row">
                        <div className="section-heading text-center">
                            <label className="sub-heading">BeenThere Metaverse</label>
                            <h2 className="heading-title">Adventure is waiting</h2>
                        </div>

                        {!show_map &&
                            <div className="subscribe">
                                <div className="form-group">
                                    <input type="button"
                                           name="submit"
                                           value="Confirm Location"
                                           className="btn"
                                           onClick={() => setState(p => ({...p, show_map: true }))}/>
                                </div>
                            </div>
                        }

                        {show_map &&
                            <WorldMap
                                className="col-lg-12 wow fadeInUp"
                                height={380}
                                trigger_location={true}
                                onLocation={handleLocation}
                            />
                        }
                    </div>
                </div>
            </section>

            <section className="work-part pt-50" id="beenthere_features">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 wow fadeInUp">
                            <div className="section-heading text-center pb-65">
                                <label className="sub-heading">Peak</label>
                                <h2 className="heading-title">{peak.name}</h2>
                                <p className="heading-des">
                                    {peak.msg}
                                </p>
                                <img src={peak.img_url || ProfileImg} alt="feature" className="banner-img-item"/>
                                <ul className="check-list heading-des">
                                    <li>
                                        <span><i className="fa fa-book" aria-hidden="true"></i></span>
                                        <p>{log_entries.length} people have claimed {peak.name}</p>
                                    </li>
                                    <li>
                                        <span><i className="fa fa-user" aria-hidden="true"></i></span>
                                        <p>{scan_count} people have scanned this QR</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row flex-all-center">
                        <div className="card-col">
                        {Object.entries(logs.slice(0, 2)).map(([idx, log_entry]) =>
                            <LogEntryCard key={`log_entry_card_${idx}`}
                                          log_entry={log_entry} />
                        )}
                        {logs.length > 2 &&
                            <div className="subscribe">
                                +{logs.length - 2} more logs
                            </div>
                        }
                        </div>
                        <div className="subscribe">
                            <div className="form-group">
                                <input type="button"
                                       name="submit"
                                       value="Claim your Peak"
                                       className="btn"
                                       onClick={handleClaim}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <StatsWidget
                totals={totals}
            />

            <Faqs/>

            <ModalBase blackout={true} show_close={false}>
                {show_login &&
                <Login showToast={showToast}
                       onClose={handleLoggedIn}
                />
                }
                {show_signin &&
                <SignupModal showToast={showToast}
                             onAlreadyMember={() => handleLogin(true)}
                             onClose={handleLoggedIn}
                />
                }
                {show_claim &&
                    <ClaimModal
                        chain_key_uid={chain_key_uid}
                        peak={peak}
                        gps={gps}
                        show_close={!show_login && !show_signin}
                        onSignup={handleSignup}
                        onClose={handleClaimed}
                        showToast={showToast}
                    />
                }
            </ModalBase>
        </>
    );

    /*
            <p>
                Elevate your adventure by claiming peaks, badges and challenges.
                Mint your achievements on decentralized storage; use them anywhere. What legacy will you build?
            </p>
            <p>Improve your play with cutting edge navigation. Now your achievements are kept on-chain forever; build your legacy</p>


            <li>
                <span><i className="fa fa-user" aria-hidden="true"></i></span>
                <p>Level-up your hike with badges, challenges and a happier life.</p>
            </li>
            <li>
                <span><i className="fa fa-user" aria-hidden="true"></i></span>
                <p>{scan_count} people have scanned this QR</p>
            </li>
            <li>
                <span><i className="fa fa-user" aria-hidden="true"></i></span>
                <p>{scan_count} people have scanned this NFT</p>
            </li>
            <li>
                <span><i className="fa fa-book" aria-hidden="true"></i></span>
                <p>{scan_count} people have minted an NFT of {peak.name}</p>
            </li>
     */
};
