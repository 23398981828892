import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Modal, Button, FormText, FormGroup, FormLabel, FormControl } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";

import Util from '../helpers/util';
import { useStore } from '../store';

import DiscoverImg from "../assets/images/chest_powerup.jpg";
import MintImg from "../assets/images/robot_scan_qr.jpg";
import CollectImg from "../assets/images/robot_group.jpg";
import ClaimImg from "../assets/images/robot_place_qr_code.jpg";

export const HowItWorks = (props) => {
    const {onClick} = props

    return (
        <section className="work-part bg-pattern pt-50 pt-15" id="how_it_works">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 wow fadeInUp">
                        <div className="section-heading text-center">
                            <label className="sub-heading">How does it
                                work?</label>
                            <h2 className="heading-title">Play the game</h2>
                            <p className="heading-des pb-20">
                                BeenThere uses decentralized tech to mint
                                digital facts of outdoor accomplishment.
                            </p>
                            <ul className="check-list heading-des pt-25">
                                <li>
                                    <label className="sub-heading">Discover</label>
                                    <p>Find a QR code on trail</p>
                                    <img src={DiscoverImg} alt="feature" className="feature-img"/>
                                </li>
                                <li>
                                    <label className="sub-heading">Mint</label>
                                    <p>Use your phone's GPS to mint proof of acquisition</p>
                                    <img src={MintImg} alt="feature" className="feature-img"/>
                                </li>
                                <li>
                                    <label className="sub-heading">Collect</label>
                                    <p>Claim peaks, badges and accomplishments on-chain</p>
                                    <img src={CollectImg} alt="feature" className="feature-img"/>
                                </li>
                                <li>
                                    <label className="sub-heading">Give back</label>
                                    <p>Place QR codes for others to find</p>
                                    <img src={ClaimImg} alt="feature"
                                         className="feature-img"/>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="subscribe">
                        <div className="form-group">
                            <input type="button"
                                   name="submit"
                                   value="Claim your Peak"
                                   className="btn"
                                   onClick={onClick}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}