import React, {useEffect, useRef, useState} from 'react';
import ReactDOM from 'react-dom';
import {Modal, Button, FormText, FormGroup, FormLabel, FormControl} from 'react-bootstrap';
import {Link, useNavigate} from "react-router-dom";

import 'mapbox-gl/dist/mapbox-gl.css';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';
import mapboxgl from 'mapbox-gl/dist/mapbox-gl-unminified.js';
import { MAP_API_KEY } from "../settings";

import {ModalBase} from "../modals/modal_base";
import { WorldMap } from "../components/world_map";
import {createHeatmap, createMarkers} from "../helpers/map";
import QuadRect from "../helpers/quad_rect";
import { useStore } from '../store';
import Util from '../helpers/util';
import {PeakModal} from "../modals/peak_modal";
import { PeakSummaryModal } from "../modals/peak_summary_modal";
import {LandSummaryModal} from "../modals/land_summary_modal";


export const Map = (props) => {
    const {showToast} = props

    const { usr_info } = useStore(x => x)

    const [state, setState] = useState({
        peak: null,
        cell_signal: 0,
        land: null,
        cell_signal_tile: 0,
        land_tile: null,
        peak_owner: null,
        land_owner: null,
        log_entries: null,
        captured: null,
        show_peak: false,
        show_detail: false,
        show_land: false,
    })
    const {peak, land, peak_owner, land_owner, log_entries, captured, show_peak, show_detail, show_land, cell_signal, cell_signal_tile, land_tile} = state

    useEffect(() => {
    }, [])

    const navigate = useRef(useNavigate())

    const handleMarker = peak => {
        //Call for details
        Util.fetch_js('/api/peak/details/', { peak_uid: peak.uid },
            js => {
                setState(prev => ({ ...prev,
                    peak: js.peak,
                    land: js.land,
                    cell_signal: js.cell_signal,
                    peak_owner: js.peak_owner,
                    land_owner: js.land_owner,
                    log_entries: js.log_entries,
                    captured: js.captured,
                }))
            })

        setState(prev => ({...prev,
            peak,
            show_peak: true,
        }))
    }

    const handleLand = (land) => {
        Util.fetch_js( '/api/cell_coverage/signal/', land,
            js => {
                setState(prev => ({...prev,
                    land_tile: land,
                    cell_signal_tile: js.signal,
                    show_land: true,
                }))
            },
            showToast)
    }

    const handleDetails = () => {
        if ( usr_info == null || !('uid' in usr_info) ) {
            console.log("Show toast?")
            showToast('Create an account', 'error')
            return
        }

        setState(prev => ({...prev,
            show_detail: true,
            show_peak: false,
        }))
    }

    const handleClose = () => {
        setState(prev => ({...prev,
            peak: null,
            land: null,
            cell_signal: 0,
            peak_owner: null,
            land_owner: null,
            log_entries: null,
            land_tile: null,
            cell_signal_tile: 0,
            show_detail: false,
            show_peak: false,
            show_land: false,
        }))
    }

    return (
        <>
            <section className="list-part white map-body" style={{}}>
                <WorldMap onMarker={handleMarker}
                          onPeak={handleMarker}
                          onLand={handleLand}
                          onMove={handleClose}
                          showToast={showToast} />
            </section>

            {show_peak &&
            <PeakSummaryModal
                peak={peak}
                peak_owner={peak_owner}
                cell_signal={cell_signal}
                captured={captured}
                onClick={handleDetails}
                onClose={handleClose}
                showToast={showToast}
            />
            }

            {!show_peak && show_land &&
                <LandSummaryModal
                    land={land_tile}
                    cell_signal={cell_signal_tile}
                    onClose={handleClose}
                    showToast={showToast}
                />
            }

            <ModalBase blackout={true}>
                {show_detail &&
                    <PeakModal peak={peak}
                          land={land}
                          cell_signal={cell_signal}
                          peak_owner={peak_owner}
                          land_owner={land_owner}
                          log_entries={log_entries}
                          captured={captured}
                          showToast={showToast}
                          onClose={handleClose}
                    />
                }
            </ModalBase>
        </>
    );
}
