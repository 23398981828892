import React, {useEffect, useRef, useState} from 'react';
import ReactDOM from 'react-dom';
import { Modal, Button, FormText, FormGroup, FormLabel, FormControl } from 'react-bootstrap';
import { Link } from "react-router-dom";
import Util from '../helpers/util';

import { useStore } from '../store';
import {createHeatmap, createMarkers} from "../helpers/map";

import mapboxgl from 'mapbox-gl/dist/mapbox-gl-unminified.js';
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';
import 'mapbox-gl/dist/mapbox-gl.css';
import QuadRect from "../helpers/quad_rect";

import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import { WorldMap } from "../components/world_map";
import { PeakCalcMap } from "../components/peak_calc_map";
import { MAP_API_KEY } from "../settings";

export const PeakCalc = (props) => {
    const {showToast} = props

    return (
        <section className="list-part white map-body" style={{}}>
            <PeakCalcMap showToast={showToast} />
        </section>
    );
}
