import React, {useState, useEffect, useRef} from "react";
import ReactDOM from "react-dom";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { Link } from "react-router-dom";

import Util from "../helpers/util";
import {toInt} from "../helpers/types";

export const CircularProgressWidget = props => {
    const prog = Math.max( Math.min( toInt(props.progress), 100 ), 0 )

    const style = { strokeDashoffset: 135 + 3.15 * (100 - prog) }

    return (
        <svg className="radial-progress-bar" style={style} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <defs>
                <linearGradient id="progress-gradient" x1="0%" x2="90%" y1="0" y2="140%">
                    <stop offset="-30%" stop-color="var(--highlight-color)" />
                    <stop offset="70.28%" stop-color="var(--primary-color)" />
                </linearGradient>
            </defs>
            <circle cx="50" cy="50" r="50" stroke="url(#progress-gradient)" stroke-linecap="round" />
        </svg>
    )
}
