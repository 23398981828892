import React, {useEffect, useRef, useState} from 'react';
import ReactDOM from 'react-dom';
import { Modal, Button, FormText, FormGroup, FormLabel, FormControl } from 'react-bootstrap';
import { Link } from "react-router-dom";
import Util from '../helpers/util';
import * as H3 from 'h3-js'

import { useStore } from '../store';
import { MAP_API_KEY } from "../settings";
import {
    createH3Projection,
    createH3Land,
    createHeatmap,
    createMarkers,
    createPolygons,
    createTerrian,
    getMapProjection,
    removeLayer, removeTerrian
} from "../helpers/map";

import mapboxgl from 'mapbox-gl/dist/mapbox-gl-unminified.js';
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';
import 'mapbox-gl/dist/mapbox-gl.css';
import QuadRect from "../helpers/quad_rect";

import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import * as geo from "../helpers/geo";

mapboxgl.workerClass = MapboxWorker;
mapboxgl.accessToken = MAP_API_KEY


export const PeakCalcMap = React.forwardRef((props, map_ref) => {
    let {height, showToast} = props

    const stylez = [
        'mapbox://styles/mapbox/outdoors-v11',
        'mapbox://styles/mapbox/outdoors-v12',
        'mapbox://styles/mapbox/satellite-streets-v11',
        'mapbox://styles/mapbox/satellite-streets-v12',
    ]
    const [style, setStyle] = useState(0)

    const [state, setState] = useState({
        moving: false,
        data_loading: false,
    })
    const {moving, data_loading} = state

    const map_status = useRef({
        lat: 47,
        lng: -116,
        zoom: 12.5,
        start_loc: null,
        cur_loc: null,
        end_loc: null,
        running: false
    })
    const map_local = useRef(null)
    const map_container = useRef(null)
    const map = (map_ref)? map_ref: map_local

    useEffect(() => {
        // initialize map only once
        if ( map.current ) {
            return;
        }

        const gps = map_status.current

        //initialize the map
        map.current = new mapboxgl.Map({
            container: map_container.current,
            style: stylez[style],
            center: [gps.lng, gps.lat],
            zoom: gps.zoom,
            doubleClickZoom: false,
            projection: 'globe',
        });

        /*
        //Setup the fullscreen control
        map.current.addControl(new mapboxgl.FullscreenControl(), 'top-right')

        //Setup
        const nav = new mapboxgl.NavigationControl({
            showZoom: false,
            showCompass: true,
            visualizePitch: true,
        });
        map.current.addControl(nav, 'bottom-right');
         */

        map.current.on('load', () => {
            //map.current.setFog( { 'star-intensity': 1 } );
            //createTerrian( map.current )
        })

        const createPoint = (name, lat, lng, elv, color ) => {
            Util.fetch_js('/api/scraper/create/', { name, lat, lng, elv },
                js => {
                    const marker = new mapboxgl.Marker({ color })
                    marker.setLngLat([lng, lat]).addTo(map.current)

                    showToast(`Created ${name}`, 'success')
                },
                showToast )
        }

        map.current.on('dblclick', e => {
            const name = window.prompt("Enter the name")
            if ( name == null || name == undefined || name == "" ) {
                return
            }

            const loc = e.lngLat
            const elv = map.current.queryTerrainElevation( loc, { exaggerated: false })

            createPoint( name, loc.lat, loc.lng, elv, 'red' )
        })

        map.current.on('click', function(e) {
            map.current.queryRenderedFeatures(e.point).forEach( f => {
                const { elevation_m, maki, name } = f.properties
                if ( f.sourceLayer != 'natural_label' || f.properties['class'] != 'landform' || maki != 'mountain' ) {
                    return
                }

                const loc = e.lngLat
                createPoint( name, loc.lat, loc.lng, elevation_m, 'green' )
            })

            /*
            if ( map_status.current.end_loc != null ) {
                console.log(e.point)
                const features = map.current.queryRenderedFeatures(e.point);

                // Limit the number of properties we're displaying for
                // legibility and performance
                const displayProperties = [
                    'type',
                    'properties',
                    'id',
                    'layer',
                    'source',
                    'sourceLayer',
                    'state'
                ];

                const displayFeatures = features.map((feat) => {
                    const displayFeat = {};
                    displayProperties.forEach((prop) => {
                        displayFeat[prop] = feat[prop];
                    });
                    return displayFeat;
                });

                console.log( displayFeatures )

                return
            }
            const loc = e.lngLat
            console.log( map.current.getZoom() )

            if ( map_status.current.start_loc == null ) {
                const marker = new mapboxgl.Marker({ color: 'green'})
                marker.setLngLat([loc.lng, loc.lat]).addTo(map.current)

                map_status.current.start_loc = loc
                map_status.current.cur_loc = loc
            }
            else {
                const marker = new mapboxgl.Marker({ color: 'red'})
                marker.setLngLat([loc.lng, loc.lat]).addTo(map.current)

                map_status.current.end_loc = loc
                const s = map_status.current.cur_loc
                map.current.setCenter([ s.lng, s.lat ])
                map.current.setZoom( 13.1 )
            }
             */
        });

        map.current.on('zoomend', function() {
            const currentZoom = map.current.getZoom();
            console.log('Zoom level changed: ' + currentZoom);
        });

        map.current.on('styledata', e => {
            //createTerrian( map.current )
        });

        map.current.on('movestart', (e) => {
            console.log('movestart')

            setState(prev => ({...prev,
                moving: true,
                data_loading: true
            }))
        });

        //Detect move ends
        map.current.on('moveend', () => {
            console.log('moveend')

            if ( !map_status.current.running ) {
                return
            }

            const center = map.current.getCenter()
            Util.fetch_js('/api/scraper/vision/', { lat: center.lat, lng: center.lng },
                js => {
                    if ( 'lat' in js && 'lng' in js ) {
                        map.current.flyTo({
                            zoom: 12.5,
                            center: [js.lng, js.lat],
                            speed: 10,
                            essential: true,
                        });
                        return
                    }

                    const move = geo.distanceBearing( center.lat, center.lng, 8000, 90)
                    console.log(move)
                    map.current.flyTo({
                        zoom: 12.5,
                        center: [move.lng, move.lat],
                        speed: 10,
                        essential: true,
                    });
                },
                err => {
                    map_status.current.running = false
                    showToast( err )
                })

            setState(prev => ({...prev,
                moving: false,
            }))
        });

        map.current.on('sourcedata', e => {
            if ( !e.isSourceLoaded ) {
                return
            }
            console.log('sourcedata')

            setState(prev => ({...prev,
                data_loading: false,
            }))
        });
    })

    useEffect(() => {
        //Get a list of all members for this tenant descending order.
        Util.fetch_js( '/api/peak/list/', {},
            (js) => {

                js.peaks.forEach( loc => {
                    const marker = new mapboxgl.Marker( { color: 'orange' } )
                    marker.setLngLat( [loc.lng, loc.lat] ).addTo( map.current )
                })
            }, showToast )
    }, [])

    const handleStart = () => {
        const center = map.current.getCenter()

        map_status.current.running = true
        map.current.flyTo({
            zoom: 12.5,
            center: [center.lng, center.lat],
            speed: 1,
            essential: true,
        });
    }

    const center = map.current? map.current.getCenter(): {}

    return (
            <div className="container-fluid" style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
                <button className="btn btn-primary" style={{ position: 'absolute', y:0, zIndex:10000}} onClick={ handleStart}>
                    Start
                    {center.lat}, {center.lng}
                </button>
                <div ref={map_container}
                     className="map-container container"
                     style={{ height: `${height}px`, flexGrow: 1 }} />
            </div>
        )
})