import React, {useEffect, useRef, useState} from 'react';
import ReactDOM from 'react-dom';
import {Modal, Button, FormText, FormGroup, FormLabel, FormControl} from 'react-bootstrap';
import {Link, useNavigate} from "react-router-dom";

import {ModalBase} from "../modals/modal_base";
import { WorldMap } from "../components/world_map";
import {createHeatmap, createMarkers} from "../helpers/map";
import QuadRect from "../helpers/quad_rect";
import { useStore } from '../store';
import Util from '../helpers/util';

import ProfileImg from '../assets/images/logo_icon.svg'

export const PeakOwnerCard = (props) => {
    const {owner, peak} = props

    return (
        <div className="card">
            <div className="log-info">
                <h3>{owner.name}</h3>
                <p>
                    {Util.friendlyDate(peak.created_on)}
                </p>
                <p>
                    {owner.bio}
                </p>
            </div>
            <img className="log-img" src={owner.profile_url || ProfileImg}/>
        </div>
    );
}
