import React, {useState, useEffect, useRef} from "react";
import ReactDOM from "react-dom";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { Link } from "react-router-dom";

import Util from "../helpers/util";
import {CircularProgressWidget} from "./circular_progress_widget";

export const BadgeWidget = props => {
    const { badge, progress, showToast } = props;

    console.log( progress )

    //Badge has no progress
    if ( progress == null ) {
        return (
            <div className="badge-widget">
                <div className="badge-progress-wrapper">
                    <div className="badge-progress">
                        <div className="outer">
                            <div className="inner">
                                <img src={badge.ghost_url} />
                            </div>
                        </div>
                        <svg className="radial-progress-bar" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="50" cy="50" r="50" strokeLinecap="round" strokeDashoffset="450" />
                        </svg>
                    </div>
                </div>

                <label className="badge-width-name">{badge.name}</label>
                <label>{badge.short_desc}</label>
            </div>
        );
    }
    //Badge has progress
    else if ( progress.earned_on == null ) {
        return (
            <div className="badge-widget">
                <div className="badge-progress-wrapper">
                    <div className="badge-progress">
                        <div className="outer">
                            <div className="inner">
                                <img src={badge.ghost_url} />
                            </div>
                        </div>
                        <CircularProgressWidget
                            progress={progress.progress}
                            />
                    </div>
                </div>
                <label className="badge-width-name">{badge.name}</label>
                <label>{badge.short_desc}</label>

                <label className="progress-percent">{progress.progress}%</label>
            </div>
        );
    }
    //Bade is earned
    else {
        return (
            <div className="badge-widget">
                <div className="badge-progress-wrapper">
                    <div className="badge-progress">
                        <div className="outer">
                            <div className="inner">
                                <img src={badge.image_url} />
                            </div>
                        </div>
                        <svg className="radial-progress-bar" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="50" cy="50" r="50" stroke-linecap="round" stroke-dashoffset="130" />
                        </svg>
                    </div>
                </div>
                <label className="badge-width-name">{badge.name}</label>
                <label>Earned on {Util.friendlyDate(progress.earned_on)}</label>
            </div>
        );
    }
};
