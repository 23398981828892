import React, {useEffect, useRef, useState} from 'react';
import ReactDOM from 'react-dom';
import {Modal, Button, FormText, FormGroup, FormLabel, FormControl} from 'react-bootstrap';
import {Link, useNavigate} from "react-router-dom";
import Util from "../helpers/util";

import LogoImg from '../assets/images/logo_icon.svg'
import StarImg from '../assets/images/star.png'
import {CellSignal} from "../components/cell_signal";


export const PeakSummaryModal = (props) => {
    const {favorited, peak, peak_owner, cell_signal, captured, onClick, onClose, showToast} = props

    useEffect(() => {
    }, [])

    console.log(peak_owner)

    const scan_txt = ('scans' in peak && peak.scans != 0)? peak.scans: 'None'
    const img_gray = { filter: 'grayscale(100%)' }

    return (
        <div className="modal summary">
            <div className="close" onClick={onClose}>
                X
            </div>
            <div className="heading">
                <div className="flex-column">
                    <h2 className="peak-name">
                        {false &&
                        <img src={StarImg} style={(!favorited)? img_gray: {}}/>
                        }
                        {peak.name}
                    </h2>
                    <p className="owner-name">
                        <span className="label">Owner: </span>
                        {peak_owner != null? peak_owner.name: "None"}
                    </p>
                    <p className="peak-elv">
                        <span className="label">Captured: </span>
                        {captured != null? Util.friendlyDate(captured.created_on): "Never"}
                    </p>
                </div>
                <img className="photo"
                     src={peak.img_url || LogoImg}
                     style={(captured == null)? img_gray: {}}
                     onClick={() => onClick( peak )}
                />
            </div>
            <div className="stats stats--row">
                <div>
                    <h4>Elevation</h4>
                    <p>{Util.numberWithCommas(peak.elv * 3.28084)} ft</p>
                </div>
                <div>
                    <h4>Scans</h4>
                    <p>{scan_txt}</p>
                </div>
                <CellSignal cell_signal={cell_signal} />
            </div>
            <div className="btn-wrapper">
            <input type="button"
                   name="view-details"
                   className="button"
                   value="View Details"
                   onClick={() => onClick( peak )}/>
            <span className="psuedos"></span>
            </div>
        </div>
    );
}
