import {toFloat} from "./types";

export const pitch = (e) => {
    const a = e.accelerationIncludingGravity
    const mag = Math.sqrt( a.x * a.x + a.y * a.y + a.z * a.z )
    if ( mag <= 0 ) {
        return
    }

    const vec = a.z / mag
    //console.log(`Acc ${pitch} ${vec} ${mag} ${a.x} ${a.y} ${a.z}`)
    return Math.acos( vec ) * 180.0 / Math.PI
}

export const bearing = (event) => {
    // Convert degrees to radians
    let alphaRad = event.alpha * (Math.PI / 180);
    let betaRad = event.beta * (Math.PI / 180);
    let gammaRad = event.gamma * (Math.PI / 180);

    // Calculate equation components
    let cA = Math.cos(alphaRad);
    let sA = Math.sin(alphaRad);
    let cB = Math.cos(betaRad);
    let sB = Math.sin(betaRad);
    let cG = Math.cos(gammaRad);
    let sG = Math.sin(gammaRad);

    // Calculate A, B, C rotation components
    let rA = - cA * sG - sA * sB * cG;
    let rB = - sA * sG + cA * sB * cG;
    let rC = - cB * cG;

    // Calculate compass heading
    let bearing = Math.atan(rA / rB);

    // Convert from half unit circle to whole unit circle
    if ( rB < 0 ) {
        bearing += Math.PI;
    }
    else if ( rA < 0 ) {
        bearing += 2 * Math.PI;
    }

    // Convert radians to degrees
    return bearing * 180.0 / Math.PI
}

export const enablePitch = ( callback ) => {
    if ( window.DeviceMotionEvent ) {
        window.addEventListener("devicemotion", (e) => {
            callback( pitch(e) )
        }, false);

        return true
    }

    return false
}

export const enableBearing = ( callback ) => {
    if ( window.DeviceOrientationEvent ) {
        window.addEventListener( "deviceorientation", (e) => {
            callback( bearing(e) )
        }, false );

        return true
    }

    return false
}

export const enableGps = (callback) => {
    navigator.geolocation.getCurrentPosition((location) => {
        callback({
            lat: location.coords.latitude,
            lng: location.coords.longitude,
            elv: toFloat(location.coords.altitude),
            spd: toFloat(location.coords.speed),
        })
    })
}
