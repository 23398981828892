import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Modal, Button, FormText, FormGroup, FormLabel, FormControl } from 'react-bootstrap';
import { Link, useNavigate, useParams } from "react-router-dom";

import Util from "../helpers/util";
import {BadgeWidget} from "../components/badge_widget";
import {AccomplishmentWidget} from "../components/accomplishment_widget";
import { ModalBase } from "../modals/modal_base";
import { PeakModal } from "../modals/peak_modal";

export const Peak = (props) => {
    const { showToast } = props;

    const {tab} = useParams()

    const [state, setState] = useState({
        peaks: [],
    })
    const { peaks, show_detail } = state

    const navigate = useRef(useNavigate())

    useEffect(() => {
        Util.fetch_js('/api/peak/my_peaks/', {},
            js => {
                setState(prev => ({...prev,
                    peaks: js.my_peaks,
                }))
            },
            showToast)
    }, [])

    const handlePeak = peak_uid => {
        Util.fetch_js('/api/peak/details/', { peak_uid },
            js => {
                setState(prev => ({...prev,
                    show_detail: js,
                }))
            },
            showToast)
    }

    const handleClose = () => {
        setState(prev => ({...prev,
            show_detail: null,
        }))
    }

    const calcPeaks = (peaks) => {
        const result = []
        const peak_idx = {}
        peaks.forEach(peak => {
            if ( peak.peak.uid in peak_idx ) {
                result[peak_idx[peak.peak.uid]].count += 1
            }
            else {
                peak_idx[peak.peak.uid] = result.length
                result.push({ ...peak, count: 1 })
            }
        })

        return result
    }

    const peak_counts = calcPeaks(peaks)

    const img_act = { color: 'var(--action-color)' }
    const img_gray = { filter: 'grayscale(100%)' }

    return (
        <>
            <section className="h-100 gradient-form ptb-100 mb-50">
                <div className="container pt-100">
                    <div className="badge-widget-container">
                        {peak_counts.map((peak, k) =>
                            <AccomplishmentWidget
                                key={`peak_widget_${k}`}
                                peak={peak}
                                onClick={handlePeak}
                                showToast={showToast}
                            />
                        )}
                    </div>
                </div>
            </section>

            <ModalBase blackout={true} onClose={handleClose}>
                {show_detail &&
                    <PeakModal peak={show_detail.peak}
                               land={show_detail.land}
                               cell_signal={show_detail.cell_signal}
                               peak_owner={show_detail.peak_owner}
                               land_owner={show_detail.land_owner}
                               log_entries={show_detail.log_entries}
                               captured={show_detail.captured}
                               showToast={showToast}
                    />
                }
            </ModalBase>
        </>
    );
};
