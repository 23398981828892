import QuadRect from "./quad_rect";
import mapboxgl from "mapbox-gl/dist/mapbox-gl-unminified";
import * as Geo from "./geo";
import * as H3 from 'h3-js'
import {toStr} from "./types";
import StarImg from '../assets/images/star.png'
import Util from "./util";


export const heatmapColor = (color) => {
    if ( color == "red") {
        return [
            'interpolate',
            ['linear'],
            ['heatmap-density'],
            0,
            'rgba(252,203,196, 0)',
            0.2,
            'rgb(242,136,121)',
            0.4,
            'rgb(255,33,59)',
            0.6,
            'rgb(229,29,53)',
            0.8,
            'rgb(204,268,47)',
            1,
            'rgb(178,24,43)'
        ]
    }
    else if ( color == "green" ) {
        return [
            'interpolate',
            ['linear'],
            ['heatmap-density'],
            0,
            'rgba(120, 137, 136, 0.0)',
            0.2,
            //'rgba(115,92,227,0.8)', //Purple!!!???
            'rgba(64,180,176,0.8)', //Blue
            0.4,
            'rgb(127,205,0)', //Orignal
            0.6,
            'rgb(229,245,204)', //Lighter tint
            0.8,
            //'rgb(125,182,45)',
            'rgb(191,230,128)', //Tint
            1,
            'rgb(127,205,0)',
        ]
    }
    else if ( color == "purple" ) {
        return [
            'interpolate',
            ['linear'],
            ['heatmap-density'],
            0,
            'rgba(120, 137, 136, 0.0)',
            0.2,
            'rgba(64,180,176,0.8)', //Blue
            0.4,
            'rgb(115,92,227)', //Orignal
            0.6,
            'rgb(213,206,247)', //Lighter tint
            0.8,
            //'rgb(125,182,45)',
            'rgb(157,141,235)', //Tint
            1,
            'rgb(115,92,227)',
        ]
    }
    else if ( color == "blue" ) {
        return [
            'interpolate',
            ['linear'],
            ['heatmap-density'],
            0,
            'rgba(33,102,172,0)',
            0.2,
            'rgb(103,169,207)',
            0.4,
            'rgb(209,229,240)',
            0.6,
            'rgb(253,219,199)',
            0.8,
            'rgb(98,239,239)',
            1,
            'rgb(24,160,178)'
        ]
    }
    //This sucks
    else if ( color == "yellow" ) {
        return [
            'interpolate',
            ['linear'],
            ['heatmap-density'],
            0,
            'rgba(247,245,255,0)',
            0.2,
            'rgb(247,245,207)',
            0.4,
            'rgb(247,245,160)',
            0.6,
            'rgb(247,240,112)',
            0.8,
            'rgb(247,234,49)',
            1,
            'rgb(252,236,7)'
        ]
    }

    return null
}

export const createHeatmap = ( map, name, points, color ) => {
    const heatmap_color = heatmapColor(color)
    if ( heatmap_color == null ) {
        return "ERror in color"
    }

    const geojson = {
        type: 'FeatureCollection',
        features: points.map( x => ({
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [x.lng, x.lat],
            }
        }))
    }

    /*
    properties: {
        title: kennel.name,
        description: ('details' in kennel) ? kennel.details : '',
    }
     */

    map.addSource(`${name}-heatsrc`, {
        'type': 'geojson',
        'data': geojson,
        //'data': 'https://docs.mapbox.com/mapbox-gl-js/assets/earthquakes.geojson'
    });

    map.addLayer(
        {
            'id': `${name}-heatlayer`,
            'type': 'heatmap',
            'source': `${name}-heatsrc`,
            'maxzoom': 11,
            'paint': {
                // Increase the heatmap weight based on frequency and property magnitude
                'heatmap-weight': [
                    'interpolate',
                    ['linear'],
                    ['get', 'mag'],
                    0,
                    0,
                    6,
                    1
                ],
                // Increase the heatmap color weight weight by zoom level
                // heatmap-intensity is a multiplier on top of heatmap-weight
                'heatmap-intensity': [
                    'interpolate',
                    ['linear'],
                    ['zoom'],
                    0,
                    1,
                    9,
                    3
                ],
                // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
                // Begin color ramp at 0-stop with a 0-transparancy color
                // to create a blur-like effect.
                'heatmap-color': heatmap_color,

                // Adjust the heatmap radius by zoom level
                'heatmap-radius': [
                    'interpolate',
                    ['linear'],
                    ['zoom'],
                    0,
                    2,
                    9,
                    20
                ],
                // Transition from heatmap to circle layer by zoom level
                'heatmap-opacity': [
                    'interpolate',
                    ['linear'],
                    ['zoom'],
                    7,
                    1,
                    9,
                    0
                ]
            }
        },
        'waterway-label'
    );
}

export const removeTerrian = ( map ) => {
    if ( !('mapbox-dem' in map.getStyle().sources) ) {
        return false
    }

    map.setTerrain()
    map.removeSource( 'mapbox-dem' )

    return true
}

export const createTerrian = ( map ) => {
    if ( 'mapbox-dem' in map.getStyle().sources ) {
        return false
    }

    // Add terrain source, with slight exaggeration
    map.addSource('mapbox-dem', {
        'type': 'raster-dem',
        'url': 'mapbox://mapbox.terrain-rgb',
        'tileSize': 512,
        'maxzoom': 14
    });
    map.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 1.5 });

    return true
}

export const createPeakCircles = ( map, name, peaks, color ) => {
    //Custom colors
    if ( color == "green" ) {
        color = "#7fcd00"
    }
    else if ( color == "blue" ) {
        color = "#18A0B2"
    }
    else if ( color == "purple" ) {
        color = "#735ce3"
    }

    map.addSource(`${name}-src`, {
        type: 'geojson',
        data: {
            type: 'FeatureCollection',
            features: peaks.map( x => ({
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [x.lng, x.lat]
                }
            }))
        }
    });

    map.addLayer({
        id: `${name}-layer`,
        type: 'circle',
        source: `${name}-src`,
        paint: {
            'circle-radius': {
                'base': 2,
                'stops': [
                    [1, 0],
                    [6, 1],
                    [8, 2],
                    [10, 4],
                    [12, 30],
                    [16, 40]
                ],
            },
            'circle-color': color,
            'circle-opacity': 0.8,
        }
    }, 'aeroway-polygon');
}

export const createMarkers = ( map, peaks, my_peaks, my_favorites, existing={}, onClick=null ) => {
    //Create a new set of pins
    const bounds = map.getBounds()
    const quad = QuadRect.raw( bounds._ne.lat, bounds._ne.lng,
                               bounds._sw.lat, bounds._sw.lng )

    let markers = {}

    //console.log( bounds._ne )
    //console.log( bounds._sw )
    //console.log( my_peaks )

    //Add my markers
    peaks.forEach( peak => {
        const { uid, lat, lng } = peak

        if ( !quad.contains( lat, lng ) ) {
            //console.log("PU")
            return
        }

        //Is this already built? Do nothing with it then
        if ( uid in existing ) {
            markers[uid] = existing[uid]
            delete existing[uid]
            return
        }

        // create a HTML element for each feature
        let marker = null
        if ( 'img_url' in peak && toStr(peak.img_url) != '' ) {
            const el = document.createElement('div');
            el.className = 'marker';
            el.style = "background-image: url(" + peak.img_url + ");"
            el.style.width = '32px';
            el.style.height = '32px';
            el.style.backgroundSize = '100%'
            el.style.borderRadius = '50%'
            el.style.cursor = ''
            if ( uid in my_peaks ) {
                el.style.boxShadow = '0px 0px 2px 2px var(--action-color)'

                const count = document.createElement('div');
                count.style.border = '2px solid var(--action-color)'
                count.style.borderRadius = '50%'
                count.style.backgroundColor = 'white'
                count.style.position = 'absolute'
                count.style.right = '-8px'
                count.style.bottom = '-8px'
                count.style.width = '24px'
                count.style.height = '24px'
                count.style.textAlign = 'center'
                count.innerHTML = my_peaks[uid]
                el.appendChild( count )
            }
            else if ( uid in my_favorites ) {
                const star = document.createElement('div');
                star.style.position = 'absolute'
                star.style.right = '-8px'
                star.style.bottom = '-8px'
                star.style.width = '24px'
                star.style.height = '24px'
                star.style = "background-image: url(" + StarImg + ");"
                el.appendChild( star )

                el.style.border = '2px solid white'
                el.style.filter = 'grayscale(100%)'
            }
            else {
                //el.style.boxShadow = '0px 0px 2px 2px var(--highlight-color)'
                el.style.border = '2px solid white'
                el.style.filter = 'grayscale(100%)'
            }


            // make a marker for each feature and add to the map
            marker = new mapboxgl.Marker(el)
        }
        else {
            let color = undefined
            if ( !(uid in my_peaks) ) {
                color = "gray"
            }
            /*
            else if ( !peak.owned ) {
                color = "gray"
            }
             */
            marker = new mapboxgl.Marker({ color })
        }

        /*
        //Setup clicking on the markers
        let children = marker.getElement().children
        const el = document.createElement('div');
        el.innerHTML = peak.name
        el.style.color = "purple"
        el.style.fontSize = "14px"
        el.style.fontWeight= 500
        children[0].parentNode.insertBefore( el, children[0].nextSibling )
         */

        /*
        marker.getElement().addEventListener('click', () => {
            console.log(peak)
            new mapboxgl.Popup()
                .setLngLat([peak.lng, peak.lat])
                .setHTML(peak.msg)
                .addTo(map);
        });
         */

        //const popup = new mapboxgl.Popup({ offset: new mapboxgl.Point( -18, -42) } ).setHTML(`<label class="sub-heading">${peak.msg}</label>`)

        //Add the marker into my array
        const m = {
            marker: marker.setLngLat([lng, lat])/*.setPopup(popup)*/.addTo(map),
            peak
        }
        markers[uid] = m

        //Attach a click?
        if ( onClick != null ) {
            marker.getElement().addEventListener('click', e => {
                e.stopPropagation();

                //Fire the callback
                onClick( m )
            });
        }
    })

    //Anything that still exists in existing now needs to be removed
    Object.values(existing).forEach(x => {
        //console.log(x)
        x.marker.remove()
    })

    return markers
}

/*
export const createPolygons = (map, id, peaks, dist) => {
    const coordinates = peaks.map( peak => {
        // Make a hex
        return [0,1,2,3,4,5].map( b => {
            const ret = Geo.distanceBearing(peak.lat, peak.lng, dist, b * 60 + 30)
            return [ret.lng, ret.lat]
        })
    })

    console.log( coordinates )

    map.addSource(id, {
        'type': 'geojson',
        'data': {
            'type': 'Feature',
            'geometry': {
                'type': 'Polygon',
                coordinates,
            }
        }
    });
    map.addLayer({
        id,
        'type': 'fill',
        source: id,
        'layout': {},
        'paint': {
            'fill-color': '#088',
            'fill-opacity': 0.8
        }
    });
}
*/

export const removeLayer = ( map, id ) => {
    try {
        if ( `${id}_source` in map.getStyle().sources ) {
            map.removeLayer(`${id}_layer`)
            map.removeSource(`${id}_source`)
        }
    } catch {}
}

export const createH3Projection = (map, id, poly, res) => {
    const cells = H3.polygonToCells( poly, res)
    const coordinates = cells.map( cell => [H3.cellToBoundary( cell, true ).slice( 0, -1)])

    //Clean up
    if ( id in map.getStyle().sources ) {
        removeLayer( map, id )
    }

    map.addSource(id, {
        'type': 'geojson',
        'data': {
            'type': 'Feature',
            'geometry': {
                'type': 'MultiPolygon',
                coordinates,
            }
        }
    });
    map.addLayer({
        id,
        'type': 'line',
        source: id,
        'layout': {},
        'paint': {
            'line-color': '#088',
            'line-width': 1,
            'line-opacity': 0.3,
        }
    });
}

export const removeH3Land = ( map, id ) => {
    removeLayer( map, `${id}`)
    removeLayer( map, `${id}_fill`)
    removeLayer( map, `${id}_border`)
}

export const drawH3Cells = (map, id, cells) => {
    const coords = cells.map( cell => [H3.cellToBoundary( cell, true )])

    //Clean up
    removeLayer( map, id)

    //Add in the child
    map.addSource(`${id}_source`, {
        'type': 'geojson',
        'data': {
            'type': 'Feature',
            'geometry': {
                'type': 'MultiPolygon',
                coordinates: coords,
            }
        }
    });
    map.addLayer({
        id: `${id}_layer`,
        'type': 'fill',
        source: `${id}_source`,
        'layout': {},
        'paint': {
            'fill-color': '#887BB0',
            'fill-opacity': 0.16
        }
    });
}

export const createH3Land = (map, id, cells, border_res=0) => {
    const border_cells = []
    if ( border_res > 0 ) {
        const parents = {}
        cells.forEach( cell => {
            parents[H3.cellToParent( cell, border_res )] = true
        })

        //Create a border cell draw list
        Object.keys(parents).forEach( cell => {
            H3.cellToChildren( cell, border_res + 1 ).forEach( x => {
                border_cells.push( x )
            })
            //border_cells.push( cell )
        })
    }

    const border_coords = border_cells.map( cell => [H3.cellToBoundary( cell, true )])
    const fill_coords = cells.map( cell => [H3.cellToBoundary( cell, true )])

    //Clean up
    removeH3Land( map, id )

    //Add in the child
    map.addSource(`${id}_fill_source`, {
        'type': 'geojson',
        'data': {
            'type': 'Feature',
            'geometry': {
                'type': 'MultiPolygon',
                coordinates: fill_coords,
            }
        }
    });
    map.addLayer({
        id: `${id}_fill_layer`,
        'type': 'fill',
        source: `${id}_fill_source`,
        'layout': {},
        'paint': {
            'fill-color': '#0bd',
            'fill-opacity': 0.2
        }
    });

    //Parent
    map.addSource(`${id}_border_source`, {
        'type': 'geojson',
        'data': {
            'type': 'Feature',
            'geometry': {
                'type': 'MultiPolygon',
                coordinates: border_coords,
            }
        }
    });
    map.addLayer({
        id: `${id}_border_layer`,
        'type': 'line',
        source: `${id}_border_source`,
        'layout': {},
        'paint': {
            'line-color': '#00b',
            'line-width': 1,
            'line-opacity': 0.3,
        }
    });
}

export const getMapProjection = ( map ) => {
    const canvas = map.getCanvas()
    const w = canvas.width
    const h = canvas.height

    const cUL = map.unproject([0,0]).toArray()
    const cUR = map.unproject([w,0]).toArray()
    const cLR = map.unproject([w,h]).toArray()
    const cLL = map.unproject([0,h]).toArray()

    return [
        [cUL[1], cUL[0]],
        [cUR[1], cUR[0]],
        [cLR[1], cLR[0]],
        [cLL[1], cLL[0]]
    ]//,cUL]
}
