import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Modal, Button, FormText, FormGroup, FormLabel, FormControl } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";

import Util from '../helpers/util';
import { useStore } from '../store';

import Team1 from "../assets/images/team-1.jpg"
import Team2 from "../assets/images/team-2.jpg"
import Team3 from "../assets/images/team-3.jpg"
import Team4 from "../assets/images/team-4.jpg"
import {WorldMap} from "../components/world_map";
import {Faqs} from "../components/faqs";
import {StatsWidget} from "../components/stats_widget";
import { BannerWidget } from "../components/banner_widget";
import { FeaturesWidget } from "../components/features_widget";
import { HowItWorks } from "../components/how_it_works";
import {DiscordWidget} from "../components/discord_widget";
import {WhatIsThisWidget} from "../components/what_is_this_widget";

export const Landing = (props) => {
    const { showToast } = props;

    const [scrollPosition, setScrollPosition] = useState(0);
    const handleScroll = () => {
        setScrollPosition( window.scrollY )
    };

    const [state, setState] = useState({
        totals: { peak_count: 0, scan_count: 0, human_count: 0, },
    })
    const { totals } = state

    const {usr_info} = useStore(x => x)

    const navigate = useRef( useNavigate())

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        Util.fetch_js('/api/dashboard/totals/', {},
            js => {
                setState( prev => ({...prev,
                    totals: js,
                }) )
            }, showToast )

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleStartHere = () => {
        if ( usr_info != null && 'uid' in usr_info ) {
            navigate.current('/map')
        }
        else {
            navigate.current('/signup')
        }
    }

    /*
    Let's face it, most of our lives are fake.
    Most people only have 2-3 trails they hike.
        BeenThere gives you a reason to conquer your environment.
        The peaks are waiting...
     */

    return (
        <>
            <BannerWidget
                title="BEEN THERE"
                subtitle="Welcome to"
                button="Start Here"
                onClick={handleStartHere}
                />

            <WhatIsThisWidget
                onClick={handleStartHere}
                />

            <HowItWorks
                onClick={handleStartHere}
            />

            <DiscordWidget />

            <FeaturesWidget
                desc={"QR codes are placed on peaks around the world."}
                onClick={handleStartHere}
                />

            <section className="work-part white mt-100 mb-50" id="how_it_works">
                <div className="container">
                    <div className="row">
                        <div className="section-heading text-center">
                            <label className="sub-heading">BeenThere Metaverse</label>
                            <h2 className="heading-title">Adventure is waiting</h2>
                        </div>

                        <WorldMap
                            className="col-lg-12 wow fadeInUp"
                            height={380}
                            showToast={showToast}
                        />
                    </div>
                </div>
            </section>

            <StatsWidget
                totals={totals}
            />

            {/* Build a running list of the last 5 scans */}

            <section id="team" className="team-part mt-25">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 wow fadeInUp">
                            <div className="section-heading text-center pb-65">
                                <label className="sub-heading">meet the team</label>
                                <h2 className="heading-title">Our Team</h2>
                                <p className="heading-des">
                                    BeenThere is a community lead DAO composed of crypto punks that are charged with the development and maintenance of the core system.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 wow fadeInLeft pb-45">
                            <div className="team-box flex-align">
                                <div className="team-img">
                                    <a ><img src={Team1} alt="team member" className="img-rounded"/></a>
                                </div>
                                <div className="team-des">
                                    <a className="member-name">Luke Dupin</a>
                                    <p className="member-des">
                                        Founder, racer, libertarian and learner.
                                        Truth is like a lion, when released it will defend itself.
                                        The best way to love humanity is to decentralize power.
                                    </p>
                                    <ul className="pt-15">
                                        <li><a ><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
                                        <li><a ><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
                                        <li><a ><i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 pb-45 wow fadeInRight">
                            <div className="team-box flex-align">
                                <div className="team-img">
                                    <a ><img src={Team4} alt="team member" className="img-rounded"/></a>
                                </div>
                                <div className="team-des">
                                    <a className="member-name">Dave Dutro</a>
                                    <p className="member-des">
                                        I am an avid trail runner, mountain biker and hiker.
                                        My goal is to share my love for trails.
                                        I want to use my passion to help you learn about trail advocacy and while doing so share some amazing places and great memories.
                                    </p>
                                    <ul className="pt-15">
                                        <li><a ><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
                                        <li><a ><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
                                        <li><a ><i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Faqs />
        </>
    );
};
