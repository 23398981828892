import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Modal, Button, FormText, FormGroup, FormLabel, FormControl } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";

import Util from "../helpers/util";
import { useStore } from "../store";
import {enableGps} from "../helpers/device_orientation";
import Refresh from "../assets/images/refresh.png";
import AnonymousImg from "../assets/images/anonymous.jpg";
import Close from "../assets/images/close.svg";
import { toStr } from "../helpers/types";

export const ClaimModal = (props) => {
    const { chain_key_uid, peak, onSignup, onClose, showToast } = props;
    const show_close = (props.show_close == undefined || props.show_close)

    const [state, setState] = useState({
        gps: props.gps,
        msg: "",
        gps_enabled: false,
        override: false,
        name: '',
        incognito: false,
    })
    const { msg, gps, gps_enabled, override, name, incognito } = state

    const { usr_info, setUsrInfo } = useStore( x => x )

    const navigate = useRef(useNavigate())

    const is_logged_in = 'uid' in usr_info && toStr(usr_info.uid) != ''

    useEffect(() => {
        //Auto request GPS
        if ( props.gps == null ) {
            handleEnableGps()
        }
    }, [])

    const handleClaim = () => {
        const safe_gps = gps || { lat: null, lng: null, elv: null }

        //Should we do something if we had to override the GPS?
        if ( !override ) {
        }

        const payload = {
            chain_key_uid,
            name,
            msg,
            lat: safe_gps.lat,
            lng: safe_gps.lng,
            elv: safe_gps.elv,
            nsfw: false,
            incognito,
        }
        const url = is_logged_in ? '/api/qr_code/claim/' : '/api/qr_code/claim_anonymous/'
        Util.fetch_js(url, payload,
            js => {
                showToast('Success', 'success')
                onClose( true )

                //If we just ran anonymous, then we want to call is valid to complete the login process
                if ( !is_logged_in ) {
                    Util.fetch_js('/api/human/is_valid/', {},
                        js => {
                            if ( js.logged_in ) {
                                setUsrInfo( js.human )
                                navigate.current('/peak')
                            }
                        },
                        showToast )
                }
                else {
                    navigate.current('/peak')
                }
            },
            showToast )
    }

    const handleChange = (e) => {
        setState(prev => ({...prev,
            [e.target.name]: e.target.value
        }))
    }

    const handleEnableGps = () => {
        enableGps( gps => {
            setState(prev => ({...prev,
                gps,
            }))
        })

        //Set the timeout to work around for IOS sucking
        setTimeout(() => {
            setState(prev => ({...prev,
                override: true,
            }))
        }, 2000)

        //Set that we have enabled the GPS
        setState(prev => ({...prev,
            gps_enabled: true,
        }))
    }

    return (
        <>
            <div>
                {/*<div className="heading-close" onClick={() => onClose(false)}> X </div>*/}
                {show_close &&
                <img src={Close}
                     className="close-btn"
                     onClick={() => onClose(false)}/>
                }
            </div>
            <div className="card rounded-3 text-black mt-0">
                <div className="row g-0">
                    <div className="col-md-12">
                        <div className="card-body text-center p-md-5 mx-md-4">
                            <label className="sub-heading">Claim</label>
                            <h3 className="community-title pb-25">{peak.name}</h3>

                            <div className="card-body">
                                <div className="d-flex flex-start w-100">
                                    <div className="w-100">
                                        {is_logged_in && <>
                                            <div className="form-outline flex-all-center mb-15">
                                                <img className="claim-profile"
                                                     src={usr_info.profile_url}/>
                                            </div>

                                            <div className="form-outline flex-all-center mb-15">
                                                <input type="text"
                                                       className="form-control singup-input claim-nickname"
                                                       readOnly={true}
                                                       placeholder="Nickname"
                                                       name="name"
                                                       value={usr_info.name}
                                                       aria-label="Nickname" />
                                            </div>
                                        </>
                                        }

                                        {!is_logged_in && <>
                                            <div className="form-outline flex-all-center mb-15">
                                                <Link to={'#'}
                                                      className="signin-login"
                                                      onClick={onSignup}>
                                                    Already a member / Create?
                                                </Link>
                                                <img className="claim-profile"
                                                     src={AnonymousImg}
                                                     onClick={onSignup}
                                                />
                                            </div>

                                            <div className="form-outline flex-all-center mb-15">
                                                <input type="text"
                                                       className="form-control singup-input claim-nickname"
                                                       placeholder="Nickname"
                                                       name="name"
                                                       value={name}
                                                       aria-label="Nickname"
                                                       onChange={handleChange} />
                                            </div>
                                        </>
                                        }

                                        <div className="form-outline mb-15">
                                            <textarea className="form-control"
                                                      id="textAreaExample"
                                                      placeholder="Optional message."
                                                      rows="4"
                                                      name="msg"
                                                      value={msg}
                                                      onChange={handleChange}
                                            />
                                        </div>

                                        <div className="form-outline mb-15">
                                            <input type="checkbox"
                                                   name="incognito"
                                                   checked={incognito}
                                                   aria-label="Incognito"
                                                   onChange={() => handleChange({target: {name: 'incognito', value: !incognito}})}
                                            />
                                            <label className="claim-incognito"
                                                   onClick={() => handleChange({target: {name: 'incognito', value: !incognito}})}>
                                                Incognito
                                            </label>
                                        </div>

                                        <div className="d-flex justify-content-between mt-50">
                                            {(gps != null || gps_enabled) &&
                                            <button type="button"
                                                    className="btn width-100"
                                                    style={(gps == null && !override)? { backgroundColor: "gray", color: "#fff" }: {}}
                                                    onClick={handleClaim}>
                                                Claim <i className="fas fa-long-arrow-alt-right ms-1"></i>
                                            </button>
                                            }

                                            {false && gps == null && !gps_enabled &&
                                                <button type="button" className="btn width-100" onClick={handleEnableGps}>
                                                    Request location
                                                </button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
