import React, {useState, useEffect, useRef} from "react";
import ReactDOM from "react-dom";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { Link } from "react-router-dom";

import Chart from 'react-apexcharts'
import Util from "../helpers/util";

export const TotalPeaksCard = props => {
    const { accounts, scans, showToast } = props;

    const chart = useRef(null)

    const loadGraph = (series, labels) => {
        if ( chart.current != null ) {
            chart.current.destroy()
        }

        const options = {
            series,
            labels,

            chart: {
                height: 320,
                stacked: true,
                type: "line",
                zoom: {
                    enabled: false
                },
                toolbar: {
                    show: false,
                    offsetY: -24
                }
            },
            stroke: {
                curve: "smooth",
                width: [1, 1, 1]
            },
            colors: [ "#87DCAA", "#FD6E6E", "#1482E6", ],
            fill: {
                colors: [ "#87DCAA", "#FD6E6E", "#1482E6", ],
            },

            xaxis: {
                type: "datetime"
            },
            yaxis: [
                {
                    //max: 100,
                    opposite: true,
                    title: {
                        text: 'Peaks',
                    }
                },
                {
                    title: {
                        text: 'QR Scans',
                    }
                },
                {
                    show: false,
                    max: 100
                }
            ],
            /*
            tooltip: {
                y: {
                    formatter: function(y) {
                        return y + '%'
                    }
                }
            }
            */
        };

        chart.current = new ApexCharts(
            document.querySelector("#total-peaks_chart"),
            options
        );
        chart.current.render();
    }

    useEffect(() => {
        if ( accounts.length > 0 && chart.current == null ) {
            const tick = new Date().getTime()

            const labels = Object.keys(accounts).map( k => {
                return Util.friendlyDate( new Date( tick - k * 24 * 3600 * 1000), false)
            })

            const series = [
                {name: 'Humans', type: 'area', data: accounts},
                {name: 'Scans', type: 'bar', data: scans },
            ]

            loadGraph( series, labels )
        }

        /*
        //Get a count of tenant members
        Util.fetch_js("/api/dashboard/view/", {},
            js => {
                loadGraph( series, labels )
            },
            showToast
        )
         */
    });

    return (
        <div className="col-xl-12 total-peaks-card">
            <div className="card rounded-3 text-black">
                <div className="card-body">
                    <label>Community Stats</label>
                    <div id='total-peaks_chart'></div>
                </div>
            </div>
        </div>
    );
};
