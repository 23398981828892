import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Modal, Button, FormText, FormGroup, FormLabel, FormControl } from 'react-bootstrap';
import {Link, useParams, useNavigate} from "react-router-dom";

import Util from '../helpers/util';
import { useStore } from '../store';

import { MintVirginQrCode } from "./mint_virgin_qr_code";
import {QrPlace} from "./qr_place";

export const ScanQR = (props) => {
    const {showToast} = props;
    const {chain_key_uid} = useParams()

    const { setUsrInfo } = useStore( x => x )

    const [tab, setTab] = useState(1)
    const [state, setState] = useState({
        loaded: false,
        peak: null,
        zone: null,
        log_entries: [],
        is_logged_in: false,
        scan_count: 0,
        totals: {},
        navigate: useRef(useNavigate()),
    })
    const {loaded, peak, zone, log_entries, is_logged_in, scan_count, totals, navigate} = state

    const [scrollPosition, setScrollPosition] = useState(0);
    const handleScroll = () => {
        setScrollPosition(window.scrollY)
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, {passive: true});

        scanQr( chain_key_uid )

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scanQr = ( chain_key_uid ) => {
        Util.fetch_js('/api/qr_code/scan/', {chain_key_uid},
            js => {
                setState(prev => ({ ...prev,
                    loaded: true,
                    peak: js.peak,
                    zone: js.zone,
                    log_entries: js.log_entries,
                    is_logged_in: js.is_logged_in,
                    scan_count: js.scan_count,
                    totals: js.totals,
                }))
            },
            (err) => {
                //showToast(err, "failure")
                navigate.current('/map')
            })
    }

    const handleMintQr = ( peak ) => {
        scanQr( chain_key_uid )
    }

    /*
    useEffect(() => {
        handleHash( windows.location.pathname )
        return history.listen(handleHash)
    }, [history])

    const handleHash = (location) => {
        const hash = location.hash
        if (hash && document.getElementById(hash.substr(1))) {
            // Check if there is a hash and if an element with that id exists
            document.getElementById(hash.substr(1)).scrollIntoView({behavior: "smooth"})
        }
    }
     */

    const handleChange = (e) => {
        setState(prev => ({...prev,
            [e.target.id]: e.target.value
        }))
    }

    const klass = (kls, cur_tab) => {
        return `${kls} ${(cur_tab == tab) ? 'active' : ''}`
    }

    //Do nothing until we are loaded
    if (!loaded ) {
        return (<div id="preloader"></div>)
    }
    //Mint view
    else if (peak == null) {
        return (
            <MintVirginQrCode
                is_logged_in={is_logged_in}
                totals={totals}
                chain_key_uid={chain_key_uid}
                onMinted={handleMintQr}
                showToast={showToast}
            />
        )
    }
    //QrPlace
    else {
        return (
            <QrPlace
                chain_key_uid={chain_key_uid}
                peak={peak}
                zone={zone}
                log_entries={log_entries}
                is_logged_in={is_logged_in}
                scan_count={scan_count}
                totals={totals}
                showToast={showToast}
            />
        )
    }
}
