export const LoginType = {
    None:       0,
    AuthReq:    1,
    CreateReq:  2,
    LoggedIn:   3,
};

export const PeakType = {
    TAG:    'tag',
    KNOB:   'knob',
}