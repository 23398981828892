import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { Link } from "react-router-dom";

import { useStore } from '../store';
import Util from '../helpers/util';

import Logo from '../assets/images/footer-logo.png'
import DiscordImg from '../assets/images/discord.png'
import {DISCORD_LINK} from "../settings";

export const Footer = (props) => {
    const handleDiscord = () => {
        window.open(DISCORD_LINK, '_blank');
    }

    return (
        <footer className="darkblue ptb-100">
            <div className="container">
                <div className="footer">
                    <div className="row">
                        <div className="col-lg-7 col-md-6 ">
                            <div className="footer-logo pb-25">
                                <Link to="/#banner">
                                    <img src={Logo} alt="BeenThere"/>
                                </Link>
                            </div>
                            <div className="footer-project-xn">
                                <ul>
                                    <li><img src={DiscordImg} style={{ cursor: "pointer", width: '40px', }} onClick={handleDiscord}/></li>

                                    {/* <li><a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a></li> */}
                                    <li><a href="#"><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
                                    <li><a href="#"><i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
                                    <li><a href="#"><i className="fa fa-instagram" aria-hidden="true"></i></a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-6">
                            <div className="footer-link">
                                <ul>
                                    <li><Link to="/#how_it_works">What is BeenThere</Link></li>
                                    <li><a href="#">BeenThere Apps</a></li>
                                    <li><a href="#">Join Us</a></li>
                                    <li><a href="#">Whitepaper</a></li>
                                    <li><a href="#">Contact</a></li>
                                    <li><Link to="/roadmap">Roadmap</Link></li>
                                    <li><Link to="/#team">Team</Link></li>
                                    <li><Link to="/#faq">Faq</Link></li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="copyright">
                    <div className="row">
                        <div className="col-lg-6">
                            <p>© BeenThere all Rights Reserved. Designed By <a href="#">beenthere.io</a></p>
                        </div>
                        <div className="col-lg-6">
                            <ul>
                                <li>
                                    <Link to="/terms-conditions">Terms & Condition</Link>
                                </li>
                                <li>
                                    <Link to="/privacy-policy">Privacy Policy</Link>
                                </li>
                                <li><a href="#" onClick={handleDiscord}>Contact Us</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}
