import * as Geo from "./geo";

export default class QuadRect {
    static raw(ne_lat, ne_lng, sw_lat, sw_lng ) {
        let result = new QuadRect()
        result.Top = ne_lat
        result.Right = ne_lng
        result.Bottom = sw_lat
        result.Left = sw_lng

        return result
    }

    static create_box(lat, lng, width, height) {
        //If we just want a centered rect, do that quickly
        if (width <= 0.0 && height <= 0.0) {
            return new QuadRect(lat, lng);
        }

        //Its going to be a square, calculate that
        if (height <= 0.0 || width == height) {
            const result = Geo.distanceBearing(lat, lng, width * 1.4143, 45.0);
            const dlat = result.lat - lat;
            const dlng = result.lng - lng;

            let rect = QuadRect();
            rect.Top = result.lat;
            rect.Right = result.lng;
            rect.Bottom = lat - dlat;
            rect.Left = lng - dlng;

            return rect;
        }

        //Setup the height and width
        width /= 2.0;
        height /= 2.0;

        const result = {
            lat: Geo.distanceBearing(lat, lng, width, 0.0).lat,
            lng: Geo.distanceBearing(lat, lng, height, 90.0).lng,
        }
        const dlat = result.lat - lat;
        const dlng = result.lng - lng;

        //Return the rect
        let rect = new QuadRect();
        rect.Top = result.lat;
        rect.Right = result.lng;
        rect.Bottom = lat - dlat;
        rect.Left = lng - dlng;

        return rect;
    }

    static create(points, tolerance) {
        let rect = new QuadRect();

        //Setup my starting point
        for (var i = 0; i < points.length; i++) {
            rect.add(point.lat, point.lng, tolerance);
        }

        return rect;
    }

    constructor(lat = 0, lng = 0) {
        this.Top = lat
        this.Bottom = lat
        this.Left = lng
        this.Right = lng
        this.First = (lat == 0 && lng == 0)
    }

    //Width and height
    width() {
        let center = (this.Top - this.Bottom) / 2.0 + this.Bottom
        return Geo.distance(center, this.Left, center, this.Right)
    }

    height() {
        let center = (this.Right - this.Left) / 2.0 + this.Left;
        return Geo.distance(this.Top, center, this.Bottom, center);
    }

    //Return the center point
    center() {
        return {
            lat: (this.Top - this.Bottom) / 2.0 + this.Bottom,
            lng: (this.Right - this.Left) / 2.0 + this.Left,
        }
    }

    //Set the center
    recenter(lat, lng) {
        const cent = center();

        let rect = new QuadRect()
        rect.Left = this.Left + (lng - cent.lng);
        rect.Right = this.Right + (lng - cent.lng);

        rect.Top = this.Top + (lat - cent.lat);
        rect.Bottom = this.Bottom + (lat - cent.lat);
        rect.First = false;

        return rect;
    }

    //Add a new point, increase the size of the rect if need be
    add(lat, lng, tolerance=0) {
        //Set the center to oint
        if (this.First) {
            this.Left = lng;
            this.Right = lng;
            this.Top = lat;
            this.Bottom = lat;
            this.First = false;
            return;
        }

        //Add the entry
        if (this.Top < lat + tolerance) this.Top = lat + tolerance;
        if (this.Bottom > lat - tolerance) this.Bottom = lat - tolerance;
        if (this.Right < lng + tolerance) this.Right = lng + tolerance;
        if (this.Left > lng - tolerance) this.Left = lng - tolerance;
    }

    //Contains a point
    contains(lat, lng) {
        return this.Bottom <= lat && lat <= this.Top &&
            this.Left <= lng && lng <= this.Right;
    }

    //Overlaps a rect?
    isOverlap(rect) {
        return this.Right >= rect.Left && this.Top >= rect.Bottom &&
            this.Left <= rect.Right && this.Bottom <= rect.Top;
    }

    //True if its not empty
    isEmpty() {
        return this.Top == 0.0 && this.Right == 0.0 && this.Bottom == 0.0 && this.Left == 0.0
    }
}
