import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Modal, Button, FormText, FormGroup, FormLabel, FormControl } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";

import {TotalPeaksCard} from "../cards/total_peaks_card";
import Util from "../helpers/util";
import {UserActivityCard} from "../cards/user_activity_card";
import achievementIcon from "../assets/images/achievement.svg";
import qrIcon from "../assets/images/qr-code.svg";
import calIcon from "../assets/images/calendar.svg";
import { ModalBase } from "../modals/modal_base";
import { PeakModal } from "../modals/peak_modal";
import {OwnedPeakCard} from "../cards/owned_peak_card";


export const Dashboard = (props) => {
    const { showToast } = props;

    const [state, setState] = useState({
        logs: [],
        owned_peaks: [],
        peaks_created: 0,
        my_scan_count: 0,
        my_peak_count: 0,
        accounts_days: [],
        scans_days: [],
        show_detail: null,
    })
    const { logs, owned_peaks, peaks_created, my_scan_count, my_peak_count, accounts_days, scans_days, show_detail } = state

    const navigate = useRef(useNavigate())

    useEffect(() => {
        Util.fetch_js('/api/dashboard/view/', {},
            js => {
                setState(prev => ({...prev,
                    logs: js.logs,
                    owned_peaks: js.owned_peaks,
                    peaks_created: js.peaks_created,
                    my_scan_count: js.my_scan_count,
                    my_peak_count: js.my_peak_count,
                    accounts_days: js.totalized_days.accounts,
                    scans_days: js.days.scans,
                }))
            },
            showToast)
    }, [])

    const handlePeak = peak => {
        Util.fetch_js('/api/peak/details/', { peak_uid: peak.uid },
            js => {
                setState(prev => ({...prev,
                    show_detail: js,
                }))
            },
            showToast)
    }

    const handleClose = () => {
        setState(prev => ({...prev,
            show_detail: null,
        }))
    }

    return (
        <section className="h-100 gradient-form dashboard-body">
            <div className="container">
                <div className="row d-flex justify-content-center align-items-center h-100">
                    <div className="user-stats">
                        <div className="card">
                            <img className="icon" src={achievementIcon} />
                            <div className="title">Peaks<br />Captured</div>
                            <div className="stats">{my_peak_count}</div>
                            <label>Completed</label>
                        </div>
                        <div className="card">
                            <img className="icon" src={qrIcon} />
                            <div className="title">Scans<br />Total</div>
                            <div className="stats">{my_scan_count}</div>
                            <label>Scanned</label>
                        </div>
                        <div className="card">
                            <img className="icon" src={calIcon} />
                            <div className="title">Peaks<br />Created</div>
                            <div className="stats">{peaks_created}</div>
                            <label>Created</label>
                        </div>
                    </div>
                    <TotalPeaksCard
                        accounts={accounts_days}
                        scans={scans_days}
                        showToast={showToast}
                        />
                    <div className="card-col">
                    {Object.entries(owned_peaks).map(([k,peak]) =>
                        <OwnedPeakCard
                            key={`op_card_${k}`}
                            peak={peak}
                            onClick={handlePeak}
                            />
                    )}
                    {/*Object.entries(logs).map(([k,log]) =>
                        <UserActivityCard
                            key={`ua_card_${k}`}
                            log={log}
                            peak={log.peak}
                            onClick={handlePeak}
                            />
                    )*/}
                    </div>
                </div>
            </div>

            <ModalBase blackout={true}>
                {show_detail &&
                    <PeakModal peak={show_detail.peak}
                               land={show_detail.land}
                               cell_signal={show_detail.cell_signal}
                               peak_owner={show_detail.peak_owner}
                               land_owner={show_detail.land_owner}
                               log_entries={show_detail.log_entries}
                               captured={show_detail.captured}
                               showToast={showToast}
                               onClose={handleClose}
                    />
                }
            </ModalBase>
        </section>
    );
};
