import React, {useEffect, useRef, useState} from 'react';
import ReactDOM from 'react-dom';
import { Modal, Button, FormText, FormGroup, FormLabel, FormControl } from 'react-bootstrap';
import { Dropdown, DropdownButton } from "react-bootstrap";
import { Link } from "react-router-dom";
import Util from '../helpers/util';

import { useStore } from '../store';
import { MAP_API_KEY } from "../settings";
import {createHeatmap, createMarkers} from "../helpers/map";

import mapboxgl from 'mapbox-gl/dist/mapbox-gl-unminified.js';
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';
import 'mapbox-gl/dist/mapbox-gl.css';
import QuadRect from "../helpers/quad_rect";

import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import { WorldMap } from "../components/world_map";

import ProfileImg from "../assets/images/anonymous.jpg";
import PeakImg from "../assets/images/peak_cover.png";
import Checkmark from "../assets/images/checkmark_sm.png";
import Camera from "../assets/images/camera.svg";
import { BannerWidget } from "../components/banner_widget";
import { ModalBase } from "../modals/modal_base";
import { PeakModal } from "../modals/peak_modal";
import { AvatarModal } from "../modals/avatar_modal";
import {SignupModal} from "../modals/signup_modal";

mapboxgl.workerClass = MapboxWorker;
mapboxgl.accessToken = MAP_API_KEY


export const MintVirginQrCode = (props) => {
    const {totals, chain_key_uid, onMinted, showToast} = props

    const map = useRef(null)
    const fileRef = useRef()

    const { usr_info, setUsrInfo } = useStore( x => x )
    const safe_usr_info = usr_info || {}

    const [state, setState] = useState({
        gps: null,
        requested_location: false,
        requested_access: false,
        name: "",
        msg: "",
        place_uid: '',
        //places: [],
        phone: ('uid' in safe_usr_info)? safe_usr_info.phone : '',
        access_code: '',
        ownership_valid: false,
        invalid_name: false,
        invalid_phone: false,
        invalid_ownership: false,
        raw_file: null,
        filename: '',
        avatar: null,
        show_avatar: false,
        show_create_user: false,
    })
    const { gps, requested_location, name, msg, invalid_name, place_uid, ownership_valid,
            phone, invalid_phone, requested_access, access_code, invalid_ownership, raw_file,
            filename, avatar, show_avatar, show_create_user } = state

    const gps_valid = (gps != null)
    const user_valid = ('uid' in safe_usr_info && safe_usr_info.uid != '') || ownership_valid
    const peak_valid = (name != '' && msg != '' && avatar != null)// raw_file != null)
    const mint_valid = gps_valid && user_valid && peak_valid && avatar != null

    //Funky pass through to open the file browse
    const handleFileClick = () => {
        fileRef.current.click();
    };

    //We have a selected file
    const handleFileChange = e => {
        if ( e.target.files.length <= 0 || e.target.files[0] == null ) {
            return
        }

        const raw_file = e.target.files[0];

        setState(prev => ({...prev,
            filename: raw_file.name,
            raw_file
        }))
    };

    const handleChange = (e) => {
        setState(prev => ({...prev,
            [e.target.id]: e.target.value
        }))
    }

    const handlePlaces = (places) => {
        /*
        setState(prev => ({...prev,
            places
        }))
         */
    }

    const handleRequestAccess = () => {
        Util.fetch_js('/api/human/request_reset_code/', { phone, existing: true },
            js => {
                setState(prev => ({...prev,
                    requested_access: true,
                }))

                showToast( "Sent", "success")
            },
            showToast )
    }

    const handleProveOwnership = () => {
        Util.fetch_js('/api/human/login/', { phone, access_code },
            js => {
                showToast( "Success", "success")

                //Util.scrollTo('peak')
                setUsrInfo( js )

                setState(prev => ({...prev,
                    ownership_valid: true,
                    invalid_ownership: false,
                }))
            },
            err => {
                setState(prev => ({...prev,
                    ownership_valid: false,
                    invalid_ownership: true,
                }))
            })
    }

    const handleMint = (e) => {
        if ( gps == null ) {
            showToast("Please enable user's GPS location first.", "failure")
            return
        }

        if ( avatar == null ) {
            showToast("Please select a peak logo.", "failure")
            return
        }

        let payload = {
            chain_key_uid,
            peak_logo_uid: avatar.uid,
            name,
            msg,
            phone,
            access_code,
            lat: gps.lat,
            lng: gps.lng,
            elv: gps.elv,
        }

        //Include place_uid
        if ( place_uid != '' ) {
            payload.place_uid = place_uid
        }

        Util.fetch_js('/api/qr_code/mint/', payload,
            js => {
                showToast( "Minted", "success")
                onMinted( js.peak )
            },
            err => {
                showToast( err, "failure")
            })
    }

    const handleGps = (new_gps) => {
        //Don't store null gps
        if ( new_gps == null ) {
            return
        }

        //First time, call a scroll
        if ( gps == null ) {
            if ( !user_valid ) {
                //Util.scrollTo('profile')
            }
            else {
                //Util.scrollTo('peak')
            }
        }

        setState(prev => ({...prev,
            gps: new_gps,
        }))
    }

    const handleCreateAccount = () => {
        setState(prev => ({...prev,
            requested_access: false,
            show_create_user: true,
        }))
    }

    const handleLoggedIn = () => {
        setState(prev => ({...prev,
            requested_access: false,
            show_create_user: false,
        }))
    }

    const handlePickLogo = () => {
        setState(prev => ({ ...prev,
            show_avatar: true,
        }))
    }

    const handleAvatarSelected = (avatar) => {
        setState(prev => ({ ...prev,
            avatar,
            show_avatar: false,
        }))
    }

    const strike = { textDecoration: 'line-through' }
    const img_act = { color: 'var(--primary-color)' }
    const img_gray = { filter: 'grayscale(100%)' }

    return (
        <>
            <BannerWidget
                title="Mint Peak"
                subtitle="Virgin QR"
            />

            <section className="list-part white ptb-50" id="how_it_works">
                <div className="container">
                    <div className="row">
                        <div className="section-heading text-center">
                            <label className="sub-heading">Minting Steps</label>
                            <h2 className="heading-title">What to do next?</h2>
                        </div>
                        <ul className="check-list heading-des">
                            <li>
                                <p style={(gps_valid)? strike: {}}>1. Allow location access to begin minting.</p>
                            </li>
                            <li>
                                <p style={(user_valid)? strike: {}}>2. Provide your phone number to authenticate the location.</p>
                            </li>
                            <li>
                                <p style={(peak_valid)? strike: {}}>3. Pick a badge, name the Peak (real names please), and a description.</p>
                            </li>
                            <li>
                                <p>4. Click "Mint" to execute the creation of a peak.</p>
                            </li>
                            <li>
                                <p>* All subsequent scans will capture that location for others.</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            {!requested_location &&
            <section id="location" className="list-part ptb-50">
                <div className="container">
                    <div className="row">
                        <div className="section-heading text-center">
                            <label className="sub-heading">
                                Waiting for access
                            </label>
                            <h2 className="heading-title">1. Location Access</h2>
                        </div>

                        <div className="col-md-12">
                            <div className="subscribe">
                                <div className="form-single">
                                    <input type="button"
                                           name="submit"
                                           className="button"
                                           value="Request location"
                                           onClick={() => handleChange({target: { id: 'requested_location', value: true}})}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            }

            {requested_location &&
                <section id="location" className="list-part pt-100 pb-55">
                    <div className="container">
                        <div className="row">
                            <div className="section-heading text-center">
                                <label className="sub-heading">
                                    Your current location
                                </label>
                                <h2 className="heading-title">
                                    Map
                                    {gps_valid && <img src={Checkmark} className="checkmark"/>}
                                </h2>
                            </div>

                            <WorldMap
                                className="col-lg-12 wow fadeInUp"
                                height={450}
                                ref={map}
                                query_elv={true}
                                trigger_location={true}
                                onLocation={handleGps}
                                onPlaces={handlePlaces}
                                showToast={showToast}
                            />
                        </div>
                    </div>
                </section>
            }

            <section id="profile" className="profile">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 wow fadeInUp">
                            <div className="section-heading text-center">
                                <label className="sub-heading">Site access</label>
                                <h2 className="heading-title">
                                    2. Account
                                    {user_valid && <img src={Checkmark} className="checkmark"/>}
                                </h2>
                                <p className="heading-des"></p>
                            </div>
                        </div>

                        <div className="profile-container">
                            <div className="main-profile col-md-6">
                                <div className="row">
                                    <div className="col-md-12">

                                        <div className="row justify-center">
                                            <img className="profile-image"
                                                 src={user_valid? usr_info.profile_url : ProfileImg} />
                                        </div>

                                        {!user_valid &&
                                        <div className="col-lg-3">
                                            <Link to={'#'}
                                                  className="signin-login"
                                                  onClick={handleCreateAccount}>
                                                Create account?
                                            </Link>
                                        </div>
                                        }

                                        {'uid' in safe_usr_info && safe_usr_info.uid != '' &&
                                        <div className="subscribe">
                                            <div className="form-group">
                                                <label>Name</label>
                                                <input type="name"
                                                       className="form-control"
                                                       readOnly={user_valid}
                                                       placeholder="Name"
                                                       id="name"
                                                       value={safe_usr_info.name} />
                                            </div>
                                        </div>
                                        }

                                        {!user_valid &&
                                        <div className="subscribe">
                                            <div className="form-group">
                                                <label>Phone number</label>
                                                <input type="tel"
                                                       style={invalid_phone? {'background': 'orangered'}: {}}
                                                       className="form-control"
                                                       readOnly={user_valid}
                                                       placeholder="Phone number"
                                                       id="phone"
                                                       value={phone}
                                                       onChange={handleChange}/>
                                            </div>
                                        </div>
                                        }

                                        {!user_valid &&
                                        <div className="subscribe">
                                            <div className="form-single">
                                                <input type="button"
                                                       id="submit"
                                                       value="Request code"
                                                       className="button"
                                                       onClick={handleRequestAccess}/>
                                            </div>
                                        </div>
                                        }
                                    </div>
                                </div>

                                {!user_valid && requested_access &&
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="subscribe">
                                            <div className="form-group">
                                                <label>Access code</label>
                                                <input style={invalid_ownership? {'background': 'orangered'}: {}}
                                                       className="form-control"
                                                       readOnly={user_valid}
                                                       placeholder="XXXXX"
                                                       id="access_code"
                                                       value={access_code}
                                                       onChange={handleChange}/>
                                            </div>
                                        </div>

                                        <div className="subscribe">
                                            <div className="form-single">
                                                <input type="button"
                                                       id="submit"
                                                       value="Validate"
                                                       className="button"
                                                       onClick={handleProveOwnership}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="peak" className="profile">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 wow fadeInUp">
                            <div className="section-heading text-center">
                                <label className="sub-heading">Site information</label>
                                <h2 className="heading-title">
                                    3. Peak
                                    {peak_valid && <img src={Checkmark} className="checkmark"/>}
                                </h2>
                                <p className="heading-des"></p>
                            </div>
                        </div>

                        <div className="profile-container">
                            <div className="main-profile col-md-6">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="subscribe">
                                            <div className="form-group" style={{marginBottom: '0px'}}>
                                                <label>Peak Badge</label>
                                                <div className="justify-center"
                                                     style={{ width: '100%'}}
                                                     onClick={handlePickLogo}>
                                                    <div className="profile-image">
                                                        <img className="profile-image"
                                                             src={(avatar && avatar.img_url)? avatar.img_url : PeakImg}/>
                                                        <img src={Camera} className="qr_profile_camera" />
                                                    </div>
                                                    <input
                                                        type='file'
                                                        accept='image/*'
                                                        ref={node => (fileRef.current = node)}
                                                        onChange={handleFileChange}
                                                        style={{ display: "none" }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="subscribe">
                                            <div className="form-group">
                                                <label>Name {invalid_name? "*Invalid": ""}</label>
                                                <input type="name"
                                                       style={invalid_name? {'background': 'orangered'}: {}}
                                                       className="form-control"
                                                       placeholder="Name"
                                                       id="name"
                                                       value={name}
                                                       onChange={handleChange}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="subscribe">
                                            <div className="form-group">
                                                <label>Placement Description</label>
                                                <textarea
                                                       className="form-control"
                                                       placeholder="Where did you put the tag?"
                                                       id="msg"
                                                       value={msg}
                                                       onChange={handleChange}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="mint_button" className="list-part pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="subscribe">
                                <div className="form-single">
                                    <input type="button"
                                           id="submit"
                                           value={"Mint Peak"}
                                           style={(!mint_valid)? {background: 'gray'}: {}}
                                           disabled={!mint_valid}
                                           className="button"
                                           onClick={handleMint}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="qr_progress_section">
                <div className="container">
                    <div className="row">
                        <div className="qr_progress_container">
                            <div className="flex-all-center" onClick={() => Util.scrollTo('location')}>
                                <span style={(gps_valid)? img_act: img_gray}><i className="fa fa-map"/></span>
                                <span>Location</span>
                            </div>

                            <div className="flex-all-center" onClick={() => Util.scrollTo('profile')}>
                                <span style={(user_valid)? img_act: img_gray}><i className="fa fa-user"/></span>
                                Sign-in
                            </div>

                            <div className="flex-all-center" onClick={() => Util.scrollTo('peak')}>
                                <span style={(peak_valid)? img_act: img_gray}><i className="fa fa-flag"/></span>
                                Peak
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <ModalBase blackout={true} centered={show_create_user}>
                {show_avatar &&
                    <AvatarModal
                        target="peaks"
                        showToast={showToast}
                        onSelect={handleAvatarSelected}
                        onClose={() => setState(prev => ({...prev, show_avatar: false }) )}
                        />
                }

                {show_create_user &&
                    <SignupModal showToast={showToast}
                                 onClose={handleLoggedIn}
                    />
                }
            </ModalBase>
        </>
    );
}
