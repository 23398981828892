import React, {useState, useEffect, useRef} from "react";
import ReactDOM from "react-dom";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { Link } from "react-router-dom";

import Util from "../helpers/util";
import {CircularProgressWidget} from "./circular_progress_widget";

export const AccomplishmentWidget = props => {
    const { peak, onClick, showToast } = props;

    return (
        <div className="badge-widget"
             onClick={() => onClick( peak.peak.uid )}>
            <div className="badge-progress-wrapper">
                {peak.count > 1 &&
                    <div className="badge-count">{peak.count}</div>
                }
                <div className="badge-progress">
                    <div className="outer">
                        <div className="inner">
                            <img src={peak.peak.img_url} />
                        </div>
                    </div>
                    <svg className="radial-progress-bar" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="50" cy="50" r="50" strokeLinecap="round" strokeDashoffset="130" />
                    </svg>
                </div>
            </div>
            <label className="badge-width-name">{peak.peak.name}</label>
            <label>{Util.friendlyDate(peak.created_on)} - {Util.meterToFt( peak.peak.elv )} ft.</label>
        </div>
    );
};
