import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Modal, Button, FormText, FormGroup, FormLabel, FormControl } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";

import Util from '../helpers/util';
import { useStore } from '../store';

import RobotTrackPointsImg from "../assets/images/robot_track_points.jpg"
import RobotTrophyImg from "../assets/images/robot_trophy.jpg";
import RobotProspectingImg from "../assets/images/robot_prospecting.jpg";
import RobotOwnershipImg from "../assets/images/robot_ownership.jpg";
import RobotCommunityImg from "../assets/images/robot_community.jpg";

export const WhatIsThisWidget = (props) => {
    const { onClick } = props;

    return (
        <section className="work-part ptb-50" id="beenthere_features">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 wow fadeInUp">
                        <div className="section-heading text-center pb-65">
                            <label className="sub-heading">What is this?</label>
                            <h2 className="heading-title">Bagging Peaks</h2>
                            {false && <h2 className="heading-title">D>J</h2>}
                            <p className="heading-des">
                                It's not about the journey, it's about the destination.
                                {false && <>
                                We're not about the journey, we're about the destination.
                                We're about the destination, not the journey.
                                It all starts with the end.
                                Start with the end.
                                BeenThere is a game where you level-up by reaching the top of peaks.
                                </>}
                            </p>
                            <ul className="check-list heading-des pt-25">
                                <li>
                                    <label className="sub-heading">
                                        Track your peaks
                                    </label>
                                    <p>
                                        BeenThere keeps track of the peaks you've reached.
                                        You level-up by bagging peaks, completing challenges, and earning badges.
                                    </p>
                                    <img src={RobotTrackPointsImg} alt="feature"
                                         className="feature-img"/>
                                </li>
                                <li>
                                    <label
                                        className="sub-heading">Challenges, Badges, and Achievements</label>
                                    <p>
                                        Badges are accomplishments earned by completing a goal: reach 10 peaks.
                                        Challenges are badges that are only available for a limited time or a specific day: reach 10 peaks during the month of April.
                                        Achievements are all of the peaks you've reached.
                                    </p>
                                    <img src={RobotTrophyImg} alt="feature"
                                         className="feature-img"/>
                                </li>
                                <li>
                                    <label
                                        className="sub-heading">Prospecting</label>
                                    <p>
                                        The map keeps track of which peaks you're still missing.
                                        BeenThere helps you identify, plan, and conquer all the peaks near you.
                                    </p>
                                    <img src={RobotProspectingImg} alt="feature"
                                         className="feature-img"/>
                                </li>
                                <li>
                                    <label className="sub-heading">Peak Ownership</label>
                                    <p>
                                        You can purchase beacons to place on peaks near you.
                                        The first person to mint a peak becomes the owner.
                                        Minting a peak allows others to achieve bagging a peak.
                                    </p>
                                    <img src={RobotOwnershipImg} alt="feature"
                                         className="feature-img"/>
                                </li>
                                <li>
                                    <label className="sub-heading">Community Lead Development</label>
                                    <p>
                                        Owning land tiles gives you voting rights in the community.
                                        Land tiles are acquired by minting a beacon on a vacant hex tile.
                                        Land tiles must be renewed yearly to maintain ownership.
                                        Community voting is how features are suggested, accepted, and implemented.
                                        See <Link to='/community-voice'>&nbsp;DAO</Link> for more information.
                                    </p>
                                    <img src={RobotCommunityImg} alt="feature"
                                         className="feature-img"/>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="subscribe">
                        <div className="form-group">
                            <input type="button"
                                   name="submit"
                                   value="Claim your Peak"
                                   className="btn"
                                   onClick={onClick}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}