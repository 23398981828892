import React, {useEffect, useRef, useState} from 'react';
import ReactDOM from 'react-dom';
import {Modal, Button, FormText, FormGroup, FormLabel, FormControl} from 'react-bootstrap';
import {Link, useNavigate} from "react-router-dom";
import Util from "../helpers/util";

import LogoImg from '../assets/images/logo_icon.svg'
import WifiSignal0 from '../assets/images/wifi_signal_0.svg'
import WifiSignal1 from '../assets/images/wifi_signal_1.svg'
import WifiSignal2 from '../assets/images/wifi_signal_2.svg'
import WifiSignal3 from '../assets/images/wifi_signal_3.svg'
import {CellSignal} from "../components/cell_signal";


export const LandSummaryModal = (props) => {
    const {land, cell_signal, onClose, showToast} = props

    useEffect(() => {
    }, [])

    return (
        <div className="modal summary">
            <div className="close" onClick={onClose}>
                X
            </div>
            <div className="heading">
                <div className="flex-column">
                    <h2 className="peak-name">
                        Land Tile
                    </h2>
                    <p className="owner-name">
                        <span className="label">Lat/Lng: </span>
                        {land.lat.toFixed(5)}, {land.lng.toFixed(5)}
                    </p>
                </div>
            </div>
            <div className="stats stats--row">
                <div>
                    <h4>Elevation</h4>
                    <p>{Util.numberWithCommas(land.elv * 3.28084)} ft</p>
                </div>
                <CellSignal cell_signal={cell_signal} />
            </div>
        </div>
    );
}
