import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Modal, Button, FormText, FormGroup, FormLabel, FormControl } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";

import Util from '../helpers/util';
import { useStore } from '../store';

import PlanImg from "../assets/images/robot_plan.jpg";
import PlayImg from "../assets/images/robot_hiker.jpg";
import LevelUpImg from "../assets/images/robot_level_up.jpg";

export const FeaturesWidget = (props) => {
    const { desc, onClick } = props;

    return (
        <section className="work-part ptb-50" id="beenthere_features">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 wow fadeInUp">
                        <div className="section-heading text-center pb-65">
                            <label className="sub-heading">Features</label>
                            <h2 className="heading-title">Plan, Play, Level-Up</h2>
                            <p className="heading-des">
                                {desc}&nbsp;
                                Scanning a QR'ed peak, combined with your GPS location, proves your accomplishment.
                                Collect peaks, level-up, and find your inner peace.
                            </p>
                            <ul className="check-list heading-des pt-25">
                                <li>
                                    <label className="sub-heading">Plan</label>
                                    <p>Pick a goal, prepare, and share your mission.</p>
                                    <img src={PlanImg} alt="feature"
                                         className="feature-img"/>
                                </li>
                                <li>
                                    <label className="sub-heading">Play</label>
                                    <p>Collect peaks, escape the fake, and find your inner peace.</p>
                                    <img src={PlayImg} alt="feature"
                                         className="feature-img"/>
                                </li>
                                <li>
                                    <label
                                        className="sub-heading">Level-Up</label>
                                    <p>
                                        Build your achievements on decentralized public storage.
                                        You did the work, you should own it.
                                    </p>
                                    <img src={LevelUpImg} alt="feature"
                                         className="feature-img"/>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="subscribe">
                        <div className="form-group">
                            <input type="button"
                                   name="submit"
                                   value="Claim your Peak"
                                   className="btn"
                                   onClick={onClick}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}