import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Modal, Button, FormText, FormGroup, FormLabel, FormControl } from 'react-bootstrap';
import { Link, useNavigate, useParams } from "react-router-dom";

import Util from "../helpers/util";
import {BadgeWidget} from "../components/badge_widget";
import {AccomplishmentWidget} from "../components/accomplishment_widget";
import { ModalBase } from "../modals/modal_base";
import { PeakModal } from "../modals/peak_modal";

export const Badge = (props) => {
    const { showToast } = props;

    const {tab} = useParams()

    const [state, setState] = useState({
        idx: 1,//(tab == 'achievements')? 2: 1,
        accomplishments: [],
        badges: [],
        my_badges: {},
        show_detail: null,
    })
    const { idx, accomplishments, badges, my_badges, show_detail } = state

    const navigate = useRef(useNavigate())

    useEffect(() => {
        Util.fetch_js('/api/badge/list/', {},
            js => {
                const my_badges = {}
                js.my_badges.forEach(x => {
                    my_badges[x.badge_uid] = x
                })

                setState(prev => ({...prev,
                    accomplishments: js.accomplishments,
                    badges: js.badges,
                    my_badges,
                }))
            },
            showToast)
    }, [])

    const handlePeak = peak_uid => {
        Util.fetch_js('/api/peak/details/', { peak_uid },
            js => {
                setState(prev => ({...prev,
                    show_detail: js,
                }))
            },
            showToast)
    }

    const handleIdx = (idx) => {
        setState(prev => ({...prev,
            idx,
        }))
    }

    const handleClose = () => {
        setState(prev => ({...prev,
            show_detail: null,
        }))
    }

    const img_act = { color: 'var(--action-color)' }
    const img_gray = { filter: 'grayscale(100%)' }

    console.log( my_badges )
    return (
        <>
            {idx == 0 &&
                <section className="h-100 gradient-form ptb-100 mb-50">
                    <div className="container pt-100">
                        <div className="badge-widget-container">
                            <h8>Coming Soon</h8>
                        </div>
                    </div>
                </section>
            }

            {idx == 1 &&
            <section className="h-100 gradient-form ptb-100 mb-50">
                <div className="container pt-100">
                    <div className="badge-widget-container">
                        {Object.entries(badges).map(([k,badge]) =>
                            <BadgeWidget
                                key={`badge_widget_${k}`}
                                badge={badge}
                                progress={(badge.uid in my_badges)? my_badges[badge.uid]: null}
                                showToast={showToast}
                                />
                        )}
                    </div>
                </div>
            </section>
            }

            {idx == 2 &&
            <section className="h-100 gradient-form ptb-100 mb-50">
                <div className="container pt-100">
                    <div className="badge-widget-container">
                        {Object.entries(accomplishments).map(([k,accomp]) =>
                            <AccomplishmentWidget
                                key={`accomplishment_widget_${k}`}
                                accomplishment={accomp}
                                onClick={handlePeak}
                                showToast={showToast}
                            />
                        )}
                    </div>
                </div>
            </section>
            }

            <ModalBase blackout={true} onClose={handleClose}>
                {show_detail &&
                    <PeakModal peak={show_detail.peak}
                               land={show_detail.land}
                               cell_signal={show_detail.cell_signal}
                               peak_owner={show_detail.peak_owner}
                               land_owner={show_detail.land_owner}
                               log_entries={show_detail.log_entries}
                               captured={show_detail.captured}
                               showToast={showToast}
                    />
                }
            </ModalBase>

            {false && show_detail == null &&
            <section className="qr_progress_section">
                <div className="container">
                    <div className="row">
                        <div className="qr_progress_container">
                            <div className="flex-all-center" onClick={() => handleIdx(0)}>
                                <span style={(idx == 0)? img_act: img_gray}>
                                    <i className="fa fa-map badge-nav-icons"/>
                                </span>
                                <span>Challenges</span>
                            </div>

                            <div className="flex-all-center" onClick={() => handleIdx(1)}>
                                <span style={(idx == 1)? img_act: img_gray}>
                                    <i className="fa fa-id-badge badge-nav-icons"/>
                                </span>
                                Badges
                            </div>

                            <div className="flex-all-center" onClick={() => handleIdx(2)}>
                                <span style={(idx == 2)? img_act: img_gray}>
                                    <i className="fa fa-trophy badge-nav-icons"/>
                                    {accomplishments.length > 0 &&
                                    <div className="badge-nav-icon-count">
                                        {accomplishments.length}
                                    </div>
                                    }
                                </span>
                                Achievements
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            }
        </>
    );
};
