import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Modal, Button, FormText, FormGroup, FormLabel, FormControl } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";

import Util from '../helpers/util';
import { useStore } from '../store';

import {Faqs} from "../components/faqs";
import { BannerWidget } from "../components/banner_widget";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

export const TermsConditions = (props) => {
    const { showToast } = props;

    const pp = `
**BEENTHERE.IO TERMS AND CONDITIONS**

Please read these Terms and Conditions ("Terms", "Terms and Conditions") carefully before using the BeenThere.io web application (the "Service") operated by FluidSoftwareDesigns Inc. ("us", "we", or "our").

Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Service.

**1. TERMS OF USE**

By accessing or using our Service, you agree to be bound by these Terms. If you disagree with any part of the terms, then you may not access the Service. 

**2. PRIVACY**

Your privacy is critically important to us. Our Privacy Policy outlines how we collect, use, protect and disclose your personal information. It also tells you about your privacy rights and how the law protects you. Please review our Privacy Policy.

**3. SERVICE USAGE**

You may not use the Service and may not accept the Terms if (a) you are not of legal age to form a binding contract with FluidSoftwareDesigns Inc., or (b) you are a person barred from receiving the Services under the laws of the United States or other countries including the country in which you are resident or from which you use the Services.

**4. ACCOUNT REGISTRATION**

To use certain features of the Service, you may be required to register for an account. When you register for an account, you may be required to provide us with some information about yourself. You agree that the information you provide to us is accurate and that you will keep it accurate and up-to-date at all times.

**5. USER CONTENT**

You are responsible for all Content that you upload, post, email or otherwise transmit via the Service. The Service provides you with the ability to add, create, upload, submit, distribute, or post content and other materials on the Service (collectively, the "User Content").

**6. LICENSE TO USER CONTENT**

By posting User Content, you grant us a worldwide, non-exclusive, royalty-free right (with the right to sublicense) to use, copy, reproduce, process, adapt, modify, publish, transmit, display, and distribute your User Content in any and all media or distribution methods now known or later developed.

**7. COPYRIGHT POLICY**

We respect the intellectual property rights of others. It is our policy to respond to any claim that Content posted on the Service infringes on the copyright or other intellectual property rights of any person or entity.

**8. LIMITATION OF LIABILITY**

In no event shall FluidSoftwareDesigns Inc., nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the Service.

**9. CHANGES TO TERMS**

We reserve the right, at our sole discretion, to modify or replace these Terms at any time. We will make reasonable efforts to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.

**10. GOVERNING LAW**

These Terms shall be governed and construed in accordance with the laws of the United States, without regard to its conflict of law provisions.

**11. CONTACT US**

If you have any questions about these Terms, please contact us at support@beenthere.io.

By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, please stop using the Service.

`

    return (
        <>
            <BannerWidget
                title="Terms & Conditions"
                />

            <div style={{ all: 'initial'}}>
                <ReactMarkdown children={pp} remarkPlugins={[remarkGfm]} />
            </div>

            <Faqs />
        </>
    )
}

